import {Typography, Grid, Button} from "@mui/material";
import {useTranslation} from "react-i18next";

function PageHeader({goToCreateLoan}: { goToCreateLoan: () => void }) {

    const {t} = useTranslation();

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography variant="h3" component="h3" gutterBottom>
                    {t('financing.title')} 💰
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    {t('financing.text3')}
                </Typography>

                <Typography variant="subtitle2" gutterBottom>
                    {t('financing.text4')}
                </Typography>

            </Grid>
            <Grid item>
                <Button
                    sx={{mt: {xs: 2, md: 0}}}
                    variant="contained"
                    // startIcon={<AddTwoToneIcon fontSize="small" />}
                    onClick={goToCreateLoan}
                >
                    {t('financing.newLoan')}
                </Button>
            </Grid>
        </Grid>
    );
}

export default PageHeader;
