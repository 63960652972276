import { QueryFunction, QueryKey } from "react-query";

import { supabase } from "config/supabaseClient";
import { CreatePropertyPayload, UpdatePropertyPayload } from "./types";
import { Property } from "types/entities/property.types";

const tableName = "Property";

/**
 * Request to create property.
 */
export const createPropertyRequest = async (
  createPropertyPayload: CreatePropertyPayload
): Promise<any> => {
  const payload = {
    ...createPropertyPayload,
  };
  const resp = await supabase.from(tableName).insert(payload).select();
  return resp;
};

/**
 * Request to update property.
 */
export const updatePropertyRequest = async (
  updatePropertyPayload: UpdatePropertyPayload
): Promise<any> => {
  const payload = {
    ...updatePropertyPayload,
  };
  const resp = await supabase
    .from(tableName)
    .update(payload)
    .eq("id", updatePropertyPayload.id);
  return resp;
};

export const getUserProperties: QueryFunction<Property[], QueryKey> = async ({
  queryKey,
}: any): Promise<any> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { userId }] = queryKey as [unknown, any];
  const resp = await supabase.from(tableName).select("").eq("userId", userId);

  return resp.data;
};
