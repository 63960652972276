import {
    BankInstitution,
} from "types/entities.types";
import {supabase} from "../../config/supabaseClient";
import api from "../api";

export const updateUserRequisition = async (
    requisitionId: string,
    userId: string,
    bankAccountCountry: string
) => {
    const {data, error} = await supabase
        .from("Landlord")
        .update({requisitionId, bankAccountCountry})
        .eq("userAuthId", userId)

    return {data, error};
};

export const getUserBankInfo = async (userId: string) => {
    let {data} = await supabase
        .from("User")
        .select("*")
        .eq("user", userId)
        .single();

    return data?.requisitionId;
};

/**
 * Get bank requisition ID, and then update the user data with the requisition ID in DB.
 */
export const getAgreement = async (userId: string, institutionId: string, maxHistoricalDays: number, bankAccountCountry: string, redirect?: string) => {

    const params = {
        maxHistoricalDays
    };

    if (redirect !== undefined) params['redirect'] = redirect;

    const resp = await api.get(
        `${process.env.REACT_APP_BANK_API!}/agreements/${institutionId}`, {
            params
        }
    );

    const {link, requisitionId} = resp.data;

    // save requisitionId on DB
    await updateUserRequisition(requisitionId, userId, bankAccountCountry);

    // return
    return {link};
};

// get avaialable banks
export const getBanks = async ({country}: { country: string }) => {
    const resp = await api.get(
        `/institutions`, {
            params: {
                country
            }
        }
    );

    return resp.data.data.map(
        (bank: any) =>
            ({
                id: bank.id,
                name: bank.name,
                maxHistoricalDays: bank.transaction_total_days
            } as BankInstitution)
    ) as BankInstitution[];

};
