import { createContext, useEffect, useState } from "react";

import { AuthType } from "./types";
import { supabase } from "config/supabaseClient";

/**
 * set type and initial values to context value
 */
export const AuthContext = createContext<AuthType>({
  user: undefined,
  logout: () => {},
  isTenant: false,
});

/**
 * Provider for authenticaction using supabase client.
 */
const AuthProvider = ({ children }: { children: JSX.Element }): any => {
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [isTenant, setIsTenant] = useState(false);

  const getTenantData = async (userId: any) => {
    const resp = await supabase.from("Tenant").select().eq("userId", userId);

    setIsTenant(resp.data.length > 0);
  };

  // set initial data
  useEffect(() => {
    // set session user data
    const setData = async () => {
      // get user session data
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error) throw error;
      setUser(session?.user);

      getTenantData(session.user.id);

      setLoading(false);
    };

    // if any change, update session user data (setting the listener)
    const { data: listener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setUser(session?.user);

        getTenantData(session?.user.id);

        setLoading(false);
      }
    );

    setData();

    // remove the listener
    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  const value = {
    user: user,
    logout: () => supabase.auth.signOut(),
    isTenant: isTenant,
  };

  // use a provider to pass down the value
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
