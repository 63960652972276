import {Box, Button, Card, CardActions, CardContent, Container, Grid, Typography} from "@mui/material";

const Plans = () => {

    const handleUpgradePlan = () => window.open(process.env.REACT_APP_STRIPE_PAYMENT_LINK);

    return (
        <Container>
            <Typography variant='h2'>
                Check our plans
            </Typography>
            <Grid container spacing={4} justifyContent="center" style={{padding: '2rem'}}>
                {/* Free Plan */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="div" gutterBottom>
                                Free Plan
                            </Typography>
                            <Typography variant="h3" color="text.primary" gutterBottom style={{fontSize: 50}}>
                                $0
                            </Typography>
                            <Typography variant="body1">
                                - 3 reports per month
                            </Typography>
                        </CardContent>
                        {/*<CardActions>*/}
                        {/*    <Button variant="contained" color="primary" fullWidth>*/}
                        {/*        Get Started*/}
                        {/*    </Button>*/}
                        {/*</CardActions>*/}
                    </Card>
                </Grid>

                {/* Premium Plan */}
                <Grid item xs={12} sm={6} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="div" gutterBottom>
                                Premium Plan
                            </Typography>
                            <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography variant="h3" color="text.secondary"
                                            style={{textDecoration: 'line-through', marginRight: 8, fontSize: 20}}
                                            gutterBottom>
                                    $29.99
                                </Typography>
                                <Typography style={{marginLeft: 5}}>
                                    / monthly
                                </Typography>
                            </Box>

                            <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Typography variant="h3" color="text.primary" gutterBottom style={{fontSize: 50}}>
                                    $9.99
                                </Typography>
                                <Typography style={{marginLeft: 5}}>
                                    / monthly
                                </Typography>
                            </Box>

                            <Typography variant="body2" color="text.secondary" style={{marginBottom: 10}}>
                                *Discount for early adopters
                            </Typography>

                            <Typography variant="body1">
                                - Unlimited reports per month
                            </Typography>
                            <Typography variant="body1">
                                - Access to all features
                            </Typography>
                            {/*<Typography variant="body1">*/}
                            {/*    - Access to analytics (coming soon)*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="body1">*/}
                            {/*    - Access to our AI assistant chat (coming soon)*/}
                            {/*</Typography>*/}
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="primary" fullWidth onClick={handleUpgradePlan}>
                                Upgrade to Premium Plan
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Plans;