import {
    Box,
    Button,
    Container,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

import {getAgreement, getBanks} from "../../services/bankInfo/bankInfo";
import {BankInstitution} from "types/entities.types";
import {useAuth} from "hooks/auth";
import Loader from "../../components/Loader";
import BankInformation from "../components/BankInformation";
import {countriesAvailables} from "../../constants/openBanking/api.info";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ConnectBank = () => {
    const {t} = useTranslation();

    const [banks, setBanks] = useState<BankInstitution[]>();
    const [selectedBank, setSelectedBank] = useState<string | undefined>(
        undefined
    );

    const [selectedCountry, setSelectedCountry] = useState<typeof countriesAvailables[number]['id']>(undefined);

    const [isLoading, setIsLoading] = useState(false);

    const {user} = useAuth();

    const location = useLocation();

    const redirect = location.state?.redirect;

    // get initial data
    useEffect(() => {
        (async function func() {

            if (selectedCountry !== undefined) {
                setIsLoading(true);
                // get banks
                // add spanish more popular banks as mock data
                let banks = [
                    // commnet sandbox
                    {id: "SANDBOXFINANCE_SFIN0000", name: "Nido Bank", maxHistoricalDays: 90},
                ];

                // get bank data from API
                const banksData = await getBanks({country: selectedCountry});

                banks = banks.concat(banksData);
                setBanks(banks);
                // setSelectedBank(banks[0].id);

                setIsLoading(false);
            }
        })();
    }, [selectedCountry]);

    // create agreement
    const handleCreateAgreements = async () => {
        if (!!user && selectedBank !== undefined) {

            setIsLoading(true);

            const _selectedBank = banks.filter((bank) => bank.id === selectedBank)[0];

            const {link} = await getAgreement(
                user.id,
                _selectedBank.id,
                _selectedBank.maxHistoricalDays,
                selectedCountry,
                redirect
            );

            window.location.replace(link);

            // setIsLoading(false)
        }
    };

    const handleChange = (bank: string) => {
        setSelectedBank(bank);
    };

    return (
        <>
            <Helmet>
                <title>{t('connectBank.title')}</title>
            </Helmet>

            <Container maxWidth="lg">

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        "& >*": {marginBottom: 3},
                        // width: "fit-content",
                    }}
                >
                    {/* title */}
                    <Typography variant="h3" gutterBottom>
                        {t('connectBank.title')}
                    </Typography>

                    {/*user message about bank iformation*/}
                    <BankInformation/>

                    {/*select country */}
                    <Box sx={{display: 'flex', flexDirection: 'column', "& >*": {marginBottom: 2},}}>

                        {
                            <>
                                <Typography variant="body2" color='gray'>
                                    {/*Select your bank*/}
                                    {t('connectBank.selectCountry')}
                                </Typography>

                                <Select
                                    id="select-country"
                                    value={selectedCountry}
                                    label="Select country"
                                    placeholder={'Select country'}
                                    size="small"
                                    onChange={(e) => {
                                        setSelectedCountry(e.target.value);
                                    }}
                                    sx={{maxWidth: '300px'}}
                                >
                                    {countriesAvailables?.map((country) => {
                                        return (
                                            <MenuItem key={country.id} value={country.id}>
                                                {country.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </>
                        }

                        {/* select bank */}
                        {!!banks && !isLoading && (
                            <>
                                <Typography variant="body2" color='gray'>
                                    {t('connectBank.selectBank')}
                                </Typography>

                                <Select
                                    id="select-bank"
                                    value={selectedBank}
                                    // label="Bank"
                                    label="Banco"
                                    size="small"
                                    onChange={(e) => handleChange(e.target.value)}
                                    sx={{maxWidth: '300px'}}
                                >
                                    {banks?.map((bank) => {
                                        return (
                                            <MenuItem key={bank.id} value={bank.id}>
                                                {bank.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>

                                {/* button to connect to bank */}
                                <Button
                                    onClick={handleCreateAgreements}
                                    variant="contained"
                                    disabled={selectedBank === undefined || user === undefined || isLoading || isLoading}
                                    sx={{maxWidth: '300px'}}
                                >
                                    {/* Connect my bank account */}
                                    {t('connectBank.connectMyAccount')}
                                </Button>

                            </>
                        )}

                        {isLoading && <Loader/>}

                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default ConnectBank;
