export function calculateLinearRegression(data: number[]) {
    const n = data.length;
    const xSum = data.reduce((sum, value, index) => sum + index, 0);
    const ySum = data.reduce((sum, value) => sum + value, 0);
    const xySum = data.reduce((sum, value, index) => sum + index * value, 0);
    const xSquaredSum = data.reduce((sum, value, index) => sum + index * index, 0);

    const m = (n * xySum - xSum * ySum) / (n * xSquaredSum - xSum * xSum);
    const b = (ySum - m * xSum) / n;

    return {m, b};
}

export function projectFutureValues(currentData: number[], monthsToProject: number): number[] {
    console.log(currentData)
    const {m, b} = calculateLinearRegression(currentData);
    const projections = [];

    for (let i = 0; i < monthsToProject; i++) {
        const nextMonthIndex = currentData.length + i;
        const projectedIncome = m * nextMonthIndex + b;
        projections.push(projectedIncome);
    }

    return projections;
}