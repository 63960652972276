import {Button, ButtonGroup, Container, Typography} from "@mui/material";

import {useNavigate} from "react-router-dom";

import {routes} from "config/routes";

const BaseAnalytics = ({children}: { children: any }) => {

    const navigate = useNavigate();

    const handleClick = (route) => navigate(route);

    return (
        <Container>

            <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{marginBottom: '20px'}}>
                <Button onClick={() => handleClick(`/${routes.reports.base}/${routes.reports.compare}`)}>
                    Versus
                </Button>
                <Button onClick={() => handleClick(`/${routes.reports.base}/${routes.reports.fullList}`)}>
                    Full-list
                </Button>
            </ButtonGroup>

            <>
                {children}
            </>
        </Container>
    )
}

export default BaseAnalytics;