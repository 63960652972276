import { I18nextProvider } from "react-i18next";

import { useRoutes } from "react-router";

import { CssBaseline } from "@mui/material";

import CustomQueryClientProvider from "./providers/queryClientProvider/QueryClientProvider";
import AuthProvider from "providers/authProvider/AuthProvider";
import { NotificationProvider } from "providers/notificationsProvider/NotificationsProvider";
import ThemeProvider from "./providers/theme/ThemeProvider";

import i18n from "config/i18n";

import router from "router";

function App() {
  const content = useRoutes(router);

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <CustomQueryClientProvider>
          <NotificationProvider>
            <AuthProvider>
              <>
                <CssBaseline />
                {content}
              </>
            </AuthProvider>
          </NotificationProvider>
        </CustomQueryClientProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
