export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const timeOptions = [
  { label: "1 year", value: 12 },
  { label: "6 months", value: 6 },
  { label: "3 months", value: 3 },
  { label: "1 month", value: 1 },
] as const;

export const yearsOptions = [2024, 2023] as const;
