import {useState} from "react";
import {useTranslation} from "react-i18next";

import {Box, Button, ButtonGroup} from "@mui/material";

// Language selector component
const LanguageSelector = () => {
    const {i18n} = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState(
        localStorage.getItem("selectedLanguage") || "es"
    );

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
        localStorage.setItem("selectedLanguage", lng);
    };
    return (
        <Box
            sx={{
                display: "flex",
                marginLeft: '30px',
            }}
        >
            <Button onClick={() => changeLanguage("es")}>🇪🇸</Button>
            <Button onClick={() => changeLanguage("en")}>🇬🇧</Button>
        </Box>
    );
};

export default LanguageSelector;
