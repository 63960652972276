import {Box, Button, Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {routes} from "config/routes";
import {useTranslation} from "react-i18next";

const Congratulations = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    return <>
        <Container maxWidth="lg">

            <Typography variant="h3" gutterBottom> {t('financing.congratsTitle')} </Typography>

            <Box sx={{marginY: 3}}>
                <Typography gutterBottom>
                    {t('financing.congratsText1')}
                </Typography>
            </Box>

            <Button variant="contained" sx={{width: '250px', marginRight: 3}}
                    onClick={() => navigate(`/${routes.loans.base}`)}>
                {t('financing.checkStatus')}
            </Button>
            <Button variant="outlined" sx={{width: '250px'}} onClick={() => navigate(`${routes.analytics}`)}>
                {t('general.analytics')}
            </Button>
        </Container>
    </>
}

export default Congratulations;