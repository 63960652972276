import {Box, Container, Grid, Typography} from "@mui/material";

import logo from "../../static/logoWhiteBlue.png";
import bckImage from "../../static/backgroundAuth.jpg";
import LanguageSelector from "../../components/languageSelector";

type Props = {
    children: JSX.Element;
};

const AuthLayout = (props: Props) => {
    const {children} = props;
    // const isLargeScreen = useMediaQuery("(min-width: 1280px)");

    return (
        <Box
            sx={{
                height: '100vh',
                overflow: 'hidden'
            }}
        >
            <Grid container>
                {/* left side */}
                <Grid item xs={12} md={6} style={{
                    display: "flex",
                    flexDirection: 'column',
                    marginTop: '120px',
                    alignItems: "center",
                }}>
                    <Box
                    >
                        {/* Logo */}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '120px',
                                padding: 0.5,
                                borderRadius: 0.5,
                                textAlign: 'center',
                                marginBottom: 2
                            }}
                        >
                            <img src={logo} alt={'logo'} style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '10px'
                            }}/>
                            <Typography style={{
                                fontWeight: 'bold',
                                color: 'gray'
                            }}>
                                Nidoscout.com
                            </Typography>
                        </Box>
                    </Box>

                    {/*form */}
                    <Container maxWidth="sm">
                        {/* rendere children */}
                        {children}

                        {/* render lagnuage selector */}
                        <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                            <LanguageSelector/>
                        </Box>
                    </Container>
                </Grid>

                {/* right side */}
                <Grid item xs={0} md={6} display={{xs: 'none', md: 'block'}}>
                    <img alt="404" src={bckImage} style={{width: '100%', height: '95%', objectFit: 'cover'}}/>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AuthLayout;
