import {GetUserStatsPayload} from "./types";
import {useQuery} from "react-query";
import {getUserStats} from "./requests";
import {projectFutureValues} from "../../utils/analytics";

const resource = 'userStats';

export const useGetUserStats = ({userId, dateFrom, dateTo, enabled}: GetUserStatsPayload) => {

    const {data, isLoading} = useQuery<any, Error>(
        [resource, {userId, dateFrom, dateTo}],
        async (props) => {
            const params = props['queryKey'][1] as GetUserStatsPayload;
            return await getUserStats(params);
        },
        {
            enabled: enabled && userId !== undefined,
        }
    );

    return {stats: data, isLoading};
};
