import {Alert, AlertTitle} from "@mui/material";
import {useTranslation} from "react-i18next";

const BankInformation = () => {
    const {t} = useTranslation();

    return (
        <Alert variant="outlined" severity="info">
            <AlertTitle> {t('connectBank.infoTitle')}</AlertTitle>
            {t('connectBank.infoContent')}
        </Alert>
    )
}

export default BankInformation;