import {GetUserTransactionsPayload} from "./types";
import {Transaction, TransactionType} from "../../types/entities/transactions";
import api from "../api";

export const getTransactions = async ({
                                          lastTransactionDate,
                                          dateTo,
                                          userId,
                                          requisitionId,
                                          country
                                      }: GetUserTransactionsPayload): Promise<Transaction[]> => {

    const queryParams = new URLSearchParams({
        lastTransactionDate,
        requisitionId,
        dateTo,
        country,
    });

    const results = await api.get(`/transactions/v0/${userId}?${queryParams}`)

    const transTmp = results.data.map(
        (transaction: any) => {
            const amount = parseFloat(transaction.transactionAmount_amount);

            let title = '';

            if (transaction.remittanceInformationUnstructured !== null) {
                title = transaction.remittanceInformationUnstructured
            } else if (transaction.rawTransaction.remittanceInformationUnstructuredArray) {
                title = transaction.rawTransaction.remittanceInformationUnstructuredArray.reduce((acc, curr, currentIndex) => acc + (currentIndex === 0 ? ' ' : ' -- ') + curr, '')
            }

            return {
                id: transaction.transactionId,
                amount,
                category: transaction.category,
                date: transaction.valueDate,
                title: title,
                type: amount > 0 ? TransactionType.earning : TransactionType.expense,
            } as Transaction;
        }
    );

    return transTmp as Transaction[];
};