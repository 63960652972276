import {supabase} from "../../config/supabaseClient";

export const actionsAfterSuccessRegistration = async (userId: string) => {
    // create landlord in database table
    await supabase
        .from("Landlord")
        // .insert([{ user: data.user?.id }]);
        .insert({
            userAuthId: userId
        })
}

export const signUp = async (email: string, password: string) => {
    let {data, error} = await supabase.auth.signUp({
        email: email,
        password: password,
    });

    await actionsAfterSuccessRegistration(data.user?.id);

    return {data, error};
};

export const logout = async () => {
    const {error} = await supabase.auth.signOut();

    return error;
};

export const login = async (email: string, password: string) => {
    let {data, error} = await supabase.auth.signInWithPassword({
        email,
        password,
    });

    return {data, error};
};

export const enterWithGoogle = async () => {

    // register with google
    await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
            // redirect to call back to setup info user
            // redirectTo: `${window.location.origin}/authCallback`,
            redirectTo: 'https://app.yournido.com/authCallback'
            // redirectTo: 'https://ajzrselqnmiqsgynwkuf.supabase.co/auth/v1/callback',
        },
    });

}
