import {useAuth} from "hooks/auth";
import {useGetLandlordProfile} from "services/landlord/landlord";
import {useGetUserSearchedProperties} from "services/propertiesSearched";
import {
    Container, FormControl, InputLabel, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useState} from "react";
import BaseAnalytics from "../BaseAnalytics";

const CompleteList = () => {

    const {user} = useAuth();

    const {landlord} = useGetLandlordProfile({userAuthId: user?.id})


    const {properties, isLoading} = useGetUserSearchedProperties({
            landlordId: landlord?.id
        })
    ;
    const sortPropertiesByOverallRating = (properties) => {
        return properties.sort((a, b) => {
            const ratingA = a.report?.overallRating || 0; // Default to 0 if no rating
            const ratingB = b.report?.overallRating || 0;
            return ratingB - ratingA; // Descending order
        });
    };

    const sortPropertiesByItemRating = (properties, targetItem) => {
        if (targetItem === "Overall Rating") {
            return sortPropertiesByOverallRating(properties);
        }
        return properties.sort((a, b) => {
            const ratingA = a.report?.items.find((item) => item.item === targetItem)?.rating || 0;
            const ratingB = b.report?.items.find((item) => item.item === targetItem)?.rating || 0;

            return ratingB - ratingA; // Descending order
        });
    };

    // Extract unique items for dropdown and include "Overall Rating"
    const allItems = ["Overall Rating", ...(properties?.[0]?.report?.items.map((item) => item.item) || [])];

    const [sortBy, setSortBy] = useState(allItems[0] || ""); // Default to the first available item

    const propertiesToDisplay = sortPropertiesByItemRating(properties || [], sortBy);

    return (
        <BaseAnalytics>

            <Typography variant='h2' gutterBottom>
                Your reports
            </Typography>

            {/* Dropdown for selecting sorting criteria */}
            <FormControl fullWidth style={{marginBottom: "40px", marginTop: '20px'}}>
                <InputLabel>Sort By</InputLabel>
                <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                    {allItems.map((item, index) => (
                        <MenuItem key={index} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* table */}
            <TableContainer component={Paper} sx={{overflowX: 'auto'}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">#</TableCell>
                            <TableCell align="left" sx={{minWidth: 200}}>Title</TableCell>
                            <TableCell align="left" sx={{minWidth: 200}}>Price</TableCell>
                            <TableCell align="left" sx={{minWidth: 200}}>Overall rating</TableCell>
                            {allItems.slice(1).map((itemName, index) => (
                                <TableCell key={index} align="left" sx={{minWidth: 200}}>
                                    {itemName}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {propertiesToDisplay.map((property, idx) => (
                            <TableRow key={property.id}>
                                <TableCell align="left">
                                    {idx + 1}
                                </TableCell>
                                <TableCell align="left">
                                    {property.propertyInfo.title || "No title available"}
                                </TableCell>
                                <TableCell align="left">
                                    {property.propertyInfo.price || "No price available"}
                                </TableCell>
                                <TableCell align="left">
                                    {property.report?.overallRating || "No price available"}
                                </TableCell>
                                {allItems.slice(1).map((itemName, index) => {
                                    const rating =
                                        property.report?.items.find(
                                            (item) => item.item === itemName
                                        )?.rating || "N/A";
                                    return (
                                        <TableCell key={index} align="left">
                                            {rating}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </BaseAnalytics>
    )
}

export default CompleteList;