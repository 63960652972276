import StarIcon from "@mui/icons-material/Star";
import {Box, Typography} from "@mui/material";

const Rating = ({rating}: { rating: number }) => {
    return (
        <Box sx={{
            backgroundColor: 'rgba(243,244,246,255)',
            width: 'fit-content',
            paddingY: 0.5,
            paddingX: 1,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 2.5
        }}>
            <StarIcon sx={{
                marginRight: 0.5,
            }}/>
            <Typography>
                {rating.toFixed(1)}
            </Typography>
        </Box>
    )
}

export default Rating