import { Box, Container, Typography, styled } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display={{ xs: "block", md: "flex" }}
        alignItems="center"
        textAlign={{ xs: "center", md: "left" }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1">&copy; 2024 - Nido</Typography>
        </Box>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 },
          }}
          variant="subtitle1"
        >
          Created by Nido <FavoriteIcon sx={{ fontSize: 15 }} />
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
