import {supabase} from "../../config/supabaseClient";
import {getDateMonthsAgo} from "../../pages/analytics/utils";
import {ByMonthIncomesExpenses} from "../../components/barChart/Charts";
import {ExpensesBreakdown, GeneralStats, IncomesBreakdown, StatsResponse} from "./types";

export const getUserStats = async ({userId, dateFrom, dateTo}: {
    userId: string,
    dateFrom: Date | undefined,
    dateTo: Date
}): Promise<StatsResponse> => {

    const defaultCategoriesValues = {};

    // define initial stats object
    let generalStats: GeneralStats = {incomes: 0, expenses: 0, profit: 0};
    let byMonthStats: ByMonthIncomesExpenses;
    let expensesBreakdown: ExpensesBreakdown = defaultCategoriesValues;
    let incomesBreakdown: IncomesBreakdown = defaultCategoriesValues;

    let _dateFrom: string | Date;

    // parse dates to string date
    if (dateFrom !== undefined) {
        _dateFrom = `${dateFrom.getFullYear()}-${dateFrom.getMonth() + 1}-${dateFrom.getDate()}`
    } else {
        const date = getDateMonthsAgo(24);

        _dateFrom = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    // parse to string
    const _dateTo = `${dateTo.getFullYear() + 1}-${dateTo.getMonth() + 1}-${dateTo.getDate()}`

    // get transactions for specific period time
    let {data} = await supabase
        .from("Transactions")
        .select("*")
        .eq("user_id", userId)
        .gt('valueDate', _dateFrom)
        .lt('valueDate', _dateTo)
        .order('valueDate')

    // compute general stats
    if (data) {

        // get stats month by month
        const monthlyIncomesExpenses = data.reduce((acc, curr) => {
            const value = parseFloat(curr.transactionAmount_amount);
            const date = new Date(curr.valueDate);
            const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`; // Format as "YYYY-MM"

            if (!acc[yearMonth]) {
                acc[yearMonth] = {
                    incomes: 0,
                    expenses: 0,
                };
            }

            if (value > 0) {
                acc[yearMonth].incomes += value;
            } else {
                acc[yearMonth].expenses += Math.abs(value);
            }

            return acc;
        }, {});

        // create object with required structure
        byMonthStats = Object.keys(monthlyIncomesExpenses).map(yearMonth => ({
            yearMonth: yearMonth,
            earnings: monthlyIncomesExpenses[yearMonth].incomes,
            expenses: monthlyIncomesExpenses[yearMonth].expenses,
            profit: monthlyIncomesExpenses[yearMonth].incomes - monthlyIncomesExpenses[yearMonth].expenses
        }));

        // calculate total stats
        const incomesExpenses = byMonthStats.reduce((acc, curr) => {
            acc['incomes'] += curr.earnings;
            acc['expenses'] += curr.expenses
            return acc
        }, {
            incomes: 0,
            expenses: 0,
        });

        //  create objetct with required structure for general stats
        generalStats = {
            incomes: incomesExpenses['incomes'],
            expenses: incomesExpenses['expenses'],
            profit: incomesExpenses['incomes'] - incomesExpenses['expenses']
        }

        // calculate expenses breakdown
        const categorySums = data.reduce((acc, curr) => {
            const category = curr.category;
            if (category !== null) {
                const value = parseFloat(curr.transactionAmount_amount);
                if (value < 0) {
                    if (!acc['expenses'][category]) {
                        acc['expenses'][category] = 0;
                    }
                    acc['expenses'][category] += Math.abs(value);
                } else {
                    if (!acc['incomes'][category]) {
                        acc['incomes'][category] = 0;
                    }
                    acc['incomes'][category] += value;
                }
            }
            return acc;
        }, {expenses: {...expensesBreakdown}, incomes: {...incomesBreakdown}});

        const totalExpenses = Object.values(categorySums['expenses']).reduce((sum: number, value: number) => sum + value, 0) as unknown as number;
        const totalIncomes = Object.values(categorySums['incomes']).reduce((sum: number, value: number) => sum + value, 0) as unknown as number;

        expensesBreakdown = Object.keys(categorySums['expenses']).reduce((acc, category) => {
            acc[category] = (categorySums['expenses'][category] / totalExpenses) * 100;
            return acc;
        }, {} as { [key: string]: number });

        incomesBreakdown = Object.keys(categorySums['incomes']).reduce((acc, category) => {
            acc[category] = (categorySums['incomes'][category] / totalIncomes) * 100;
            return acc;
        }, {} as { [key: string]: number });

    }

    return {
        generalStats,
        byMonthStats,
        expensesBreakdown,
        incomesBreakdown
    }

}