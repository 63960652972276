import {GetUserTransactionsPayload} from "./types";
import {getTransactions} from "./requests";
import {useEffect, useState} from "react";
import {Transaction} from "../../types/entities/transactions";

export const useGetUserTransactions = ({
                                           lastTransactionDate,
                                           requisitionId,
                                           dateTo,
                                           userId,
                                           country
                                       }: GetUserTransactionsPayload) => {

    const [isLoading, setIsLoading] = useState(true);

    const [transactions, setTransactions] = useState<Transaction[]>(undefined);

    useEffect(() => {

        (async () => {

            if (userId !== undefined && requisitionId !== null && requisitionId !== undefined && !!country) {
                setIsLoading(true);

                const resp = await getTransactions({lastTransactionDate, requisitionId, dateTo, userId, country})

                setTransactions(resp);

                setIsLoading(false);

            } else if (userId !== undefined && (requisitionId === null || requisitionId === undefined)) {
                setIsLoading(false);
            }
        })();

    }, [userId, requisitionId, country]);

    return {transactions: transactions, isLoading};
};