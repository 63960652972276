export const cities = [
  "Madrid",
  "Barcelona",
  "Valencia",
  "Seville",
  "Zaragoza",
  "Málaga",
  "Murcia",
  "Palma",
  "Las Palmas de Gran Canaria",
  "Bilbao",
  "Alicante",
  "Córdoba",
  "Valladolid",
  "Vigo",
  "Gijón",
  "Hospitalet de Llobregat",
  "Vitoria",
  "A Coruña",
  "Granada",
  "Elche",
  "Tarragona",
  "Badalona",
  "Oviedo",
  "Cartagena",
  "Terrassa",
  "Jerez de la Frontera",
  "Sabadell",
  "Santa Cruz de Tenerife",
  "Pamplona",
  "Almería",
  "Leganés",
  "Donostia-San Sebastián",
  "Burgos",
  "Santander",
  "Albacete",
  "Castellón de la Plana",
  "Alcorcón",
  "Getafe",
  "Logroño",
  "Badajoz",
  "Salamanca",
  "Huelva",
  "Marbella",
  "Lleida",
  "Tarrasa",
  "Jaén",
  "Ourense",
  "Mataró",
  "Algeciras",
  "Sant Cugat del Vallès",
  "Reus",
  "Torrejón de Ardoz",
  "Telde",
  "Barakaldo",
  "Santiago de Compostela",
  "Lugo",
  "San Cristóbal de La Laguna",
  "Parla",
  "Cáceres",
  "Las Rozas de Madrid",
  "Roquetas de Mar",
  "Lorca",
  "San Sebastián de los Reyes",
  "Santa Coloma de Gramenet",
  "Alcobendas",
  "La Línea de la Concepción",
  "Arona",
  "Coslada",
  "Orihuela",
  "El Puerto de Santa María",
  "Cornellà de Llobregat",
  "San Fernando",
  "Rivas-Vaciamadrid",
  "Benidorm",
  "Sant Boi de Llobregat",
  "Torrent",
  "Ferrol",
  "Vélez-Málaga",
  "Manresa",
  "Mijas",
  "Mollet del Vallès",
  "Pontevedra",
  "Ávila",
  "Valdemoro",
  "Gandia",
  "Fuengirola",
  "Rubí",
  "Palencia",
  "Andorra la Vella",
];

export const countries = ["España"];
