import {Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {routes} from "../../config/routes";
import {useTranslation} from "react-i18next";

const GoToBankComponent = () => {
    const {t} = useTranslation();

    const navigate = useNavigate();

    return <>
        <Card sx={{width: "fit-content", padding: 1, marginBottom: 2}}>
            <CardHeader title={t('connectBank.connectMyAccount')}/>
            <CardContent>
                <Typography>
                    {t('connectBank.messageToConnectAccount')}
                </Typography>
                <CardActions>
                    <Button
                        onClick={() => navigate(routes.connectBank)}
                        variant="contained"
                        sx={{left: -10, marginTop: '20px'}}
                    >
                        {t('connectBank.connectMyAccount')}
                    </Button>
                </CardActions>
            </CardContent>
        </Card>
    </>
}

export default GoToBankComponent