import { QueryClient, QueryClientProvider } from "react-query";

/**
 * Provider for react-query.
 */
const CustomQueryClientProvider = ({ children }: { children: JSX.Element }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default CustomQueryClientProvider;
