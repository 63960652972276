import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {NavLink as RouterLink} from "react-router-dom";

import {
    alpha,
    Box,
    List,
    styled,
    Button,
    ListItem,
} from "@mui/material";
import Home from "@mui/icons-material/Home";
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';

// import WalletIcon from "@mui/icons-material/Wallet";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
import BarChartIcon from "@mui/icons-material/BarChart";
// import TocIcon from "@mui/icons-material/Toc";
// import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FavoriteIcon from '@mui/icons-material/Favorite';

import {SidebarContext} from "contexts/SidebarContext";

import {routes} from "config/routes";
import PersonIcon from "@mui/icons-material/Person";

// TODO: move this to other file (style component)
const MenuWrapper = styled(Box)(
    ({theme}) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
    ({theme}) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
        "transform",
        "opacity",
    ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
    const {closeSidebar} = useContext(SidebarContext);
    const {t} = useTranslation();
    return (
        <MenuWrapper>
            {/* general menu */}
            <List component="div">
                <SubMenuWrapper>
                    <List component="div">
                        {/* home page */}
                        <ListItem component="div">
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to={`${routes.index}`}
                                startIcon={<Home/>}
                            >
                                {t("general.home")}
                            </Button>
                        </ListItem>

                        {/*explore invesment options */}
                        {/*<ListItem component="div">*/}
                        {/*    <Button*/}
                        {/*        disableRipple*/}
                        {/*        component={RouterLink}*/}
                        {/*        onClick={closeSidebar}*/}
                        {/*        to={`/${routes.reports.base}/${routes.reports.allProperties}`}*/}
                        {/*        startIcon={<BarChartIcon/>}*/}
                        {/*    >*/}
                        {/*        /!*{t("general.analytics")}*!/*/}
                        {/*        Explore*/}
                        {/*    </Button>*/}
                        {/*</ListItem>*/}

                        {/*explore invesment options */}
                        <ListItem component="div">
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to={`/${routes.reports.base}/${routes.reports.userProperties}`}
                                startIcon={<BarChartIcon/>}
                            >
                                {t("general.myReports")}
                            </Button>
                        </ListItem>

                        <ListItem component="div">
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to={`/${routes.reports.base}/${routes.reports.favorites}`}
                                startIcon={<FavoriteIcon/>}
                            >
                                {t("general.myFavorites")}
                            </Button>
                        </ListItem>

                        <ListItem component="div">
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to={`/${routes.reports.base}/${routes.reports.compare}`}
                                startIcon={<CandlestickChartIcon/>}
                            >
                                {t("general.compareProperties")}
                            </Button>
                        </ListItem>

                        {/*<ListItem component="div">*/}
                        {/*    <Button*/}
                        {/*        disableRipple*/}
                        {/*        component={RouterLink}*/}
                        {/*        onClick={closeSidebar}*/}
                        {/*        to={`/${routes.reports.base}/${routes.reports.analytics}`}*/}
                        {/*        startIcon={<BarChartIcon/>}*/}
                        {/*    >*/}
                        {/*        /!*{t("general.analytics")}*!/*/}
                        {/*        Analytics*/}
                        {/*    </Button>*/}
                        {/*</ListItem>*/}

                        {/* analytics */}
                        {/*<ListItem component="div">*/}
                        {/*    <Button*/}
                        {/*        disableRipple*/}
                        {/*        component={RouterLink}*/}
                        {/*        onClick={closeSidebar}*/}
                        {/*        to={routes.analytics}*/}
                        {/*        startIcon={<BarChartIcon/>}*/}
                        {/*    >*/}
                        {/*        {t("general.analytics")}*/}
                        {/*    </Button>*/}
                        {/*</ListItem>*/}

                        {/* transactions */}
                        {/*<ListItem component="div">*/}
                        {/*    <Button*/}
                        {/*        disableRipple*/}
                        {/*        component={RouterLink}*/}
                        {/*        onClick={closeSidebar}*/}
                        {/*        to={routes.transactions}*/}
                        {/*        startIcon={<TocIcon/>}*/}
                        {/*    >*/}
                        {/*        {t('general.myTransactions')}*/}
                        {/*    </Button>*/}
                        {/*</ListItem>*/}

                        {/*<ListItem component="div">*/}
                        {/*    <Button*/}
                        {/*        disableRipple*/}
                        {/*        component={RouterLink}*/}
                        {/*        onClick={closeSidebar}*/}
                        {/*        to={`/${routes.loans.base}`}*/}
                        {/*        startIcon={<LocalAtmIcon/>}*/}
                        {/*    >*/}
                        {/*        {t('general.myLoans')}*/}
                        {/*    </Button>*/}
                        {/*</ListItem>*/}

                        {/* perks */}
                        {/* <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={() => {
                  closeSidebar();
                  window.location.href = routes.perksSniptech;
                }}
                // to={routes.perksSniptech}
                to=""
                startIcon={<ShoppingCartCheckoutIcon />}
              >
                Cashback
              </Button>
            </ListItem> */}

                        {/* contracts */}
                        {/*<ListItem component="div">*/}
                        {/*  <Button*/}
                        {/*    disableRipple*/}
                        {/*    component={RouterLink}*/}
                        {/*    onClick={closeSidebar}*/}
                        {/*    to={`/${routes.contracts.base}`}*/}
                        {/*    startIcon={<AssignmentIcon />}*/}
                        {/*  >*/}
                        {/*    {t("general.contracts")}*/}
                        {/*  </Button>*/}
                        {/*</ListItem>*/}

                        {/* taxes */}
                        {/*<ListItem component="div">*/}
                        {/*  <Button*/}
                        {/*    disableRipple*/}
                        {/*    component={RouterLink}*/}
                        {/*    onClick={closeSidebar}*/}
                        {/*    to={routes.setupTaxes}*/}
                        {/*    startIcon={<AssignmentIcon />}*/}
                        {/*  >*/}
                        {/*    Mis Impuestos*/}
                        {/*  </Button>*/}
                        {/*</ListItem>*/}

                        {/* landlord */}
                        <ListItem component="div">
                            <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to={`/${routes.landlord.base}`}
                                startIcon={<PersonIcon/>}
                            >
                                {t("general.profile")}
                            </Button>
                        </ListItem>
                        {/*</List>*/}
                    </List>
                </SubMenuWrapper>
            </List>

            {/* chashback menu */}
            {/*<List*/}
            {/*    component="div"*/}
            {/*    subheader={*/}
            {/*        <ListSubheader component="div" disableSticky>*/}
            {/*            Cashback*/}
            {/*        </ListSubheader>*/}
            {/*    }*/}
            {/*>*/}
            {/*    <SubMenuWrapper>*/}
            {/*        <List component="div">*/}
            {/*            <ListItem component="div">*/}
            {/*                <Button*/}
            {/*                    disableRipple*/}
            {/*                    onClick={() => {*/}
            {/*                        closeSidebar();*/}
            {/*                        window.location.href = routes.perksSniptech;*/}
            {/*                    }}*/}
            {/*                    startIcon={<StarBorderIcon/>}*/}
            {/*                >*/}
            {/*                    /!* Beneficios *!/*/}
            {/*                    {t("perks.benefits")}*/}
            {/*                </Button>*/}
            {/*            </ListItem>*/}

            {/*            <ListItem component="div">*/}
            {/*                <Button*/}
            {/*                    disableRipple*/}
            {/*                    onClick={() => {*/}
            {/*                        closeSidebar();*/}
            {/*                        window.location.href = routes.perksSniptechDashboard;*/}
            {/*                    }}*/}
            {/*                    startIcon={<WalletIcon/>}*/}
            {/*                >*/}
            {/*                    /!* Billetera *!/*/}
            {/*                    {t("perks.wallet")}*/}
            {/*                </Button>*/}
            {/*            </ListItem>*/}
            {/*        </List>*/}
            {/*    </SubMenuWrapper>*/}
            {/*</List>*/}
        </MenuWrapper>
    );
}

export default SidebarMenu;
