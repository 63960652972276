import {Box, Typography} from "@mui/material";
import {parseNumberToString} from "../../../utils/nmbers";
import {useTranslation} from "react-i18next";

const GeneralStats = ({stats}: { stats: any }): JSX.Element => {
    const {t} = useTranslation();

    // TODO: move to constants file
    const statsLabels = {
        incomes: t('transactions.incomes'),
        expenses: t('transactions.expenses'),
        profit: t('analytics.profit')
    };

    return (
        <Box>
            <Typography variant="body1" fontWeight="bold" marginBottom={2}>
                {/* Summary */}
                {t('analytics.summary')}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}
            >
                {Object.keys(stats).map((stat) => (
                    <Box
                        key={stat}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            // backgroundColor: "#F8F9FAFF",
                            backgroundColor: 'white',
                            // boxShadow: '1px 1px;',
                            marginY: 2,
                            marginX: 1,
                            width: "fit-content",
                            paddingY: 2,
                            paddingLeft: 3,
                            paddingRight: 5,
                            borderRadius: 3,
                        }}
                    >
                        <Typography variant="body2" sx={{color: "gray", textTransform: 'capitalize'}} gutterBottom>
                            {statsLabels[stat]}
                        </Typography>
                        <Typography variant="h1">€ {parseNumberToString(stats[stat])}</Typography>
                        {/*<Typography variant="body2" sx={{ color: "gray", marginTop: 1 }}>*/}
                        {/*  <span style={{ color: "rgb(33, 95, 33)", fontWeight: "bold" }}>*/}
                        {/*    {stat.change}{" "}*/}
                        {/*  </span>{" "}*/}
                        {/*  /!* this period *!/*/}
                        {/*  este periodo*/}
                        {/*</Typography>*/}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default GeneralStats;
