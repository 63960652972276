import { Perk } from "types/entities.types";

export const perks: Perk[] = [
  {
    name: "Ikea",
    description: "5%",
    link: "https://www.ikea.com",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Ikea_logo.svg/2560px-Ikea_logo.svg.png",
  },
  {
    name: "Hannun",
    description: "2.5%",
    link: "https://hannun.com",
    image:
      "https://cdn.shopify.com/s/files/1/2481/2502/files/LOGO_HANNUN-NORM_NEGRO_1200x1200.png?v=1614287475",
  },
  {
    name: "Nespresso",
    description: "2.5%",
    link: "https://www.nespresso.com/pt/en/",
    image:
      "https://logos-world.net/wp-content/uploads/2020/12/Nespresso-Logo.png",
  },
  {
    name: "The body shop",
    description: "7%",
    link: "https://www.thebodyshop.com/",
    image:
      "https://logosmarcas.net/wp-content/uploads/2020/11/The-Body-Shop-Logo.png",
  },
  {
    name: "The body shop",
    description: "5%",
    link: "https://www.elcorteingles.com/",
    image:
      "https://www.elcorteingles.es/recursos/informacioncorporativa/doc/portal/2017/07/06/eci-triangulo-logo.png",
  },
  {
    name: "HBO Max",
    description: "5%",
    link: "https://www.hbomax.com/",
    image: "https://cdn.mos.cms.futurecdn.net/SZNYtuNM2aPhb6n6fK65qE.jpg",
  },
  {
    name: "Maison du Monde",
    description: "3%",
    link: "https://www.maisonsdumonde.com/",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f1/Maisons_du_Monde_logo.svg/2560px-Maisons_du_Monde_logo.svg.png",
  },
];
