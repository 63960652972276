import {Box, Button, Typography} from "@mui/material";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import {useTranslation} from "react-i18next";

const HelpButton = () => {

    const {t} = useTranslation();

    const handleHelp = () => {

        const userMessage = 'We will send you to our customer support chat channel.';

        if (window.confirm(userMessage)) {
            const phoneNumber = '79031233843'
            const text = 'Hello! I would like some help with Nido.';

            const url = `https://wa.me/+${phoneNumber}?text=${text}`;

            window.open(url);
        }
    }

    return <Box style={{
        display: "flex",
        marginLeft: '30px',
        marginTop: '20px'
    }}>
        <a href="mailto:vidadi@yournido.com ">
            <Button
                // variant='text'
                // onClick={handleHelp}
                startIcon={<SupportAgentIcon sx={{color: 'gray'}}/>}
            >
                <Typography sx={{
                    // color: 'rgba(181,184,189,255)',
                    fontWeight: 'bold',
                    color: 'rgba(255,255,255,0.6)'
                }}>
                    {t('general.needHelp')}
                </Typography>
            </Button>
        </a>
    </Box>
}

export default HelpButton;