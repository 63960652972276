import BaseSearchedProperties from "./components/Base";

import {useAuth} from "hooks/auth";
import {useGetLandlordProfile} from "services/landlord/landlord";
import {useGetAllSearchedProperties} from "services/propertiesSearched";
import {Container, Typography} from "@mui/material";

const AllProperties = () => {
    const {user} = useAuth();

    const {landlord} = useGetLandlordProfile({
        userAuthId: undefined
    })

    const {properties, isLoading} = useGetAllSearchedProperties();

    return <Container>
        <div style={{marginBottom: 35}}>
            <Typography variant='h3'>
                Market oportunities
            </Typography>
            <Typography>
                Explore all oportunities in the market
            </Typography>
        </div>
        <BaseSearchedProperties properties={properties} isLoading={isLoading}/>
    </Container>
}

export default AllProperties;