import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";

import { perks } from "./constants";
import PageTitleWrapper from "components/PageTitleWrapper";
import PageHeader from "./PageHeader";

const Perks = () => {
  const handleLink = (link: string) => {
    window.open(link);
  };

  return (
    <>
      <Helmet>
        <title>Nido - Cashback</title>
      </Helmet>

      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {!!perks &&
            perks.map((perk) => {
              return (
                <Grid item xs={12} sm={3}>
                  <Card sx={{}}>
                    <CardMedia
                      component="img"
                      image={perk.image}
                      height={100}
                    />

                    <CardContent>
                      {/* description */}
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant="caption" gutterBottom>
                          {perk.description}
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        size="small"
                        onClick={() => handleLink(perk.link)}
                      >
                        Comprar ahora
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </>
  );
};

export default Perks;
