import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {supabase} from "config/supabaseClient";
import Loader from "components/Loader";
import {Alert, Button, Card, CardContent, CardHeader, Container, Grid, Paper, Typography} from "@mui/material";
import {PropertySearched} from "types/entities/propertiesSearched";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Box} from "@mui/system";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import InsightsIcon from '@mui/icons-material/Insights';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import {useAuth} from "hooks/auth";
import {useGetLandlordProfile} from "services/landlord/landlord";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Rating from "components/rating";
import html2canvas from "html2canvas";
import jspdf from "jspdf";

import {routes} from "config/routes";

import {extractEurValue, extractYield} from "./utils";

type PropertyItemReport = {
    item: string,
    value: string
}

const PropertyReport = () => {

    const reportRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const [property, setProperty] = useState<PropertySearched | undefined>(undefined); // To store the response from the API
    const [loading, setLoading] = useState(true); // Loading state to show loading indicator

    const {propertyId} = useParams();

    const {user} = useAuth();
    const {landlord} = useGetLandlordProfile({userAuthId: user?.id});

    const fetchReport = async () => {

        let {data: PropertySearched, error} = await supabase
            .from('PropertySearched')
            .select("*")
            // Filters
            .eq('id', propertyId);

        let {data: UserProperty} = await supabase
            .from('User-PropertySearched')
            .select("*")
            // Filters
            .eq('propertySearched', propertyId)
            .eq('user', landlord?.id)
        ;

        const _property = {...PropertySearched[0], isFavorite: UserProperty[0]['isFavorite']} as PropertySearched;

        setProperty(_property)
        setLoading(false);

    };

    useEffect(() => {

        fetchReport();

    }, [propertyId, landlord]); // Fetch data when component mounts, and when 'link' changes

    // Conditional rendering based on the states
    if (loading) {
        return <Loader/>;
    }

    const handleGoBack = () => {
        navigate(-1);  // -1 means going back to the previous page in the history
    };

    const renderItem = (title: string, content: string, rating: number | undefined, Icon: any) => {

        return (
            <Card style={{marginBottom: 50, padding: 15}}>
                <CardContent>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between', // Spread the title and rating across the row
                        marginBottom: 10
                    }}>
                        <Box style={{display: 'flex', alignItems: 'center'}}>
                            <Icon style={{marginRight: 8}}/>
                            <Typography variant='h3' sx={{whiteSpace: 'normal'}}>
                                {title}
                            </Typography>
                        </Box>

                        {/* Box to display the rating */}
                        {rating &&
                            <Rating rating={rating}/>
                        }
                    </Box>
                    <Typography style={{
                        wordWrap: 'break-word',  // Ensures long words wrap to the next line
                        whiteSpace: 'pre-wrap',  // Preserves the new lines, tabs, etc.
                    }}>
                        {content}
                    </Typography>
                </CardContent>
            </Card>
        )
    };

    const itemIcons = {
        'Summary': InfoOutlinedIcon,
        'Price analysis': SellOutlinedIcon,
        'Location': LocationOnOutlinedIcon,
        'Rental income potential': MapsHomeWorkOutlinedIcon,
        // TODO: change icon
        'Short-term rental income potential': BeachAccessOutlinedIcon,
        'Price increase potential': TrendingUpOutlinedIcon,
        'Operating costs': InsertChartOutlinedIcon,
        // TODO: change icon
        'Additional costs breakdown': CardTravelIcon,
        'ROI': InsightsIcon
    };

    const handleClickOnFavorite = async () => {

        const {data, error} = await supabase
            .from('User-PropertySearched')
            .update({isFavorite: !property.isFavorite})
            .eq('user', landlord.id)
            .eq('propertySearched', property.id)
            .select()

        fetchReport();

    };

    const handleDownloadPdf = async () => {
        if (!reportRef.current) return;

        const canvas = await html2canvas(reportRef.current, {
            scale: 2,
            useCORS: true,
            // backgroundColor: "#f5f5f5", // Optional: Ensures background color is captured
        });

        const imgData = canvas.toDataURL("image/png");
        const pdf = new jspdf("p", "mm", "a4");

        const imgWidth = 190; // A4 width minus 10mm padding on each side
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let heightLeft = imgHeight;
        let position = 10; // Start 10mm down the page to add top padding

        pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight); // Left margin of 10mm
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
            position -= pageHeight - 10; // Add bottom margin for new pages
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
        }

        pdf.save(`property_report_${propertyId}.pdf`);
    };

    return (
        <Container>

            <Grid container sx={{marginBottom: '10px'}}>
                <Grid item xs={2}>
                    {/*back button*/}
                    <Button
                        style={{marginBottom: '20px'}}
                        onClick={handleGoBack}
                        variant='outlined'
                    >
                        <ArrowBackIcon/>
                    </Button>
                </Grid>
                <Grid item sm={10}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', sm: 'row'},
                            alignItems: {xs: 'stretch', sm: 'center'},
                            justifyContent: {sm: 'flex-end'},
                            gap: 1, // gap between buttons
                            marginTop: {xs: 6, sm: 0}
                        }}
                    >
                        <Button onClick={handleClickOnFavorite} variant='outlined'
                                style={{marginRight: 10, height: 'fit-content'}}>
                            {property.isFavorite ?
                                <FavoriteIcon style={{marginRight: 10, cursor: 'pointer'}}/>
                                :
                                <FavoriteBorderIcon style={{marginRight: 10, cursor: 'pointer'}}/>}

                            {property.isFavorite ? 'Remove from' : 'Add to'} favorites
                        </Button>

                        <Button onClick={() => window.open(property.link)} variant='outlined' sx={{marginRight: 1}}>
                            <OpenInNewIcon
                                style={{marginRight: 10, cursor: 'pointer'}}
                            />
                            View the listing
                        </Button>

                        {/* go to compare property */}
                        <Button onClick={() => navigate(`/${routes.reports.base}/${routes.reports.compare}`, {
                            state: {
                                property
                            }
                        })}
                                variant='outlined'
                                sx={{marginRight: 1}}>
                            {/*<OpenInNewIcon*/}
                            {/*    style={{marginRight: 10, cursor: 'pointer'}}*/}
                            {/*/>*/}
                            Compare
                        </Button>

                        {/*    download as pdf*/}
                        <Button onClick={handleDownloadPdf} variant='outlined'>
                            {/*<OpenInNewIcon*/}
                            {/*    style={{marginRight: 10, cursor: 'pointer'}}*/}
                            {/*/>*/}
                            Download PDF
                        </Button>

                        {/*<Button variant="outlined" onClick={handleDownloadPdf} style={{marginBottom: 20}}>*/}
                        {/*    Download PDF*/}
                        {/*</Button>*/}
                    </Box>
                </Grid>
            </Grid>

            <div ref={reportRef}>

                <Card style={{marginBottom: 30, padding: 15, paddingBottom: 0}}>
                    <CardContent>
                        {/*title*/}
                        <Typography variant='h2' sx={{marginBottom: 2}}>
                            {property.propertyInfo.title}
                        </Typography>

                        {/* general info property */}
                        <Box style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            {/*<Typography>*/}
                            {/*    <b>Property:</b> {property.propertyInfo.title}*/}
                            {/*</Typography>*/}
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: 20
                            }}>
                                <LocationOnOutlinedIcon style={{marginRight: 5}}/>
                                <Typography>
                                    {property.propertyInfo.location.replace("Ubicación", "").replace(/\n+/g, ', ').replace(/^, /, '').trim()}
                                </Typography>
                            </Box>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: 17
                            }}>
                                <SellOutlinedIcon style={{marginRight: 5}}/>
                                <Typography>
                                    {property.propertyInfo.price}
                                </Typography>
                            </Box>

                            <Box style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: 15
                            }}>
                                <HomeOutlinedIcon style={{marginRight: 5}}/>
                                <Typography style={{
                                    wordWrap: 'break-word',  // Ensures long words wrap to the next line
                                    whiteSpace: 'pre-wrap',  // Preserves the new lines, tabs, etc.
                                }}>
                                    {property.propertyInfo.infoFeatures.replace(/\s+/g, ' ').replace(/(\d+ m²)/, '$1,').trim()}
                                </Typography>
                            </Box>

                        </Box>
                    </CardContent>
                </Card>

                {/*user message */}
                <Alert
                    // icon={<CheckIcon fontSize="inherit"/>}
                    severity="success"
                    style={{marginBottom: 30}}
                >
                    Notice: The information and analysis provided on Nidoscout.com is for informational purposes only
                    and
                    does not constitute financial, investment, or real estate advice. Any decisions you make based on
                    the
                    information provided by Nidoscout.com are done at your own risk.
                </Alert>

                {/* general stats */}
                <Grid
                    container
                    rowSpacing={'20px'}
                    sx={{marginBottom: 3}}
                >
                    {[
                        {
                            label: 'Potential rental income',
                            value: `${(extractEurValue(property.report.items.find((item) => item.item === 'Rental income potential').value) || 'N/A')} € / month`
                        },
                        {
                            label: 'Gross anual yield',
                            value: `${(extractYield(property.report.items.find((item) => item.item === 'Rental income potential').value) || 'N/A')} %`
                        },
                        {
                            label: 'Overall rating', value: property.report.overallRating.toFixed(1)
                        },
                        {
                            label: 'Location rating',
                            value: property.report.items.find((item) => item.item === 'Location').rating
                        },
                    ].map((item) =>
                        <Grid item xs={12} sm={3}>
                            <Card sx={{width: '230px'}}>
                                <CardContent>
                                    <Typography gutterBottom>
                                        {item.label}
                                    </Typography>
                                    <Typography variant='h3'>
                                        {item.value}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>

                {/* items */}
                <pre>

                {/* overall report*/}
                    <>{renderItem('Summary', property.report.overall, property.report.overallRating, itemIcons['Summary'])}</>

                    {/*each item of the report*/}
                    {property.report &&
                        property.report.items.map((item) => {
                            return <div
                                key={item.item}>{renderItem(item.item, item.value, item.rating, itemIcons[item.item])}</div>
                        })
                    }</pre>
            </div>
        </Container>
    )
        ;
}

export default PropertyReport;