import { Alert, Snackbar } from "@mui/material";

import { NotificationType } from "./types";
import { notificationTime } from "./constants";

const Notification = ({
  notification,
  handleClose,
}: {
  notification: NotificationType;
  handleClose: () => void;
}) => {
  return (
    <Snackbar
      open={true}
      autoHideDuration={notificationTime}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={notification.type}
        sx={{ width: "100%" }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
