import {CircularProgress, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {useTranslation} from "react-i18next";

const Loader = ({message, bigFont = false}: { message?: string, bigFont?: boolean }) => {
    const {t} = useTranslation();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: 'center'
            }}
        >
            <CircularProgress/>

            <Typography style={{fontSize: bigFont ? '13px' : "10px", marginTop: "10px", maxWidth: '300px'}}>
                {message ? message : t('general.loading')}...
            </Typography>
        </Box>
    );
};

export default Loader;
