import { useMutation, useQuery } from "react-query";

import { Property } from "types/entities/property.types";
import { CreatePropertyPayload, UpdatePropertyPayload } from "./types";
import { MutationOptionalPayload } from "types/requests.types";
import {
  createPropertyRequest,
  getUserProperties,
  updatePropertyRequest,
} from "./properties.requests";
import { supabase } from "config/supabaseClient";

const resource = "properties";

/**
 * Hook to create property.
 */
export const useCreateProperty = ({
  onSuccess,
  onError,
}: MutationOptionalPayload) => {
  return useMutation<Property, Error, CreatePropertyPayload>({
    mutationFn: createPropertyRequest,
    onSuccess,
    onError,
  });
};

/**
 * Hook to update property.
 */
export const useUpdateProperty = ({
  onSuccess,
  onError,
}: MutationOptionalPayload) => {
  return useMutation<Property, Error, UpdatePropertyPayload>({
    mutationFn: updatePropertyRequest,
    onSuccess,
    onError,
  });
};

// get properties
// TODO: remove this function and use the hook useGetUserProperties()
export const getProperties = async (userId: string) => {
  let { data: properties } = await supabase
    .from("Property")
    .select("*")
    .eq("userId", userId);

  return properties as Property[];
};

/**
 * Hook to get all the user properties.
 */
export const useGetUserProperties = ({
  userId,
}: {
  userId: string | undefined;
}) => {
  const { data: properties, isLoading } = useQuery<Property[], Error>(
    [resource, { userId }],
    getUserProperties,
    {
      enabled: userId !== undefined,
    }
  );

  return { properties, isLoading };
};
