import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Container,
  Typography,
} from "@mui/material";

import { routes } from "config/routes";

const Base = ({ children }: { children: JSX.Element }) => {
  const navigateTo = (route: string) => {
    window.location.href = route;
  };

  const { t } = useTranslation();

  const merchants = [
    "IKEA",
    "Maisons du Monde",
    "Nespresso",
    "Miravia",
    "Casa Viva",
    "La Mallorquina",
    "Kenay Home",
    "Happy Friday",
  ];

  return (
    <>
      <Helmet>
        {/* sniptech widget */}
        <script src={process.env.REACT_APP_SNIPTECH_URL}></script>
      </Helmet>

      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          margin: "0px 20px",
        }}
      >
        {/* header section */}
        <div
          style={
            {
              // marginLeft: "40px"
            }
          }
        >
          <div>
            <Typography variant="h3" gutterBottom>
              Cashback 💸
            </Typography>
            <Typography gutterBottom>
              {/* Disfruta de ofertas especiales y recompensas de nuestros socios. */}
              {t("perks.text1")}
            </Typography>
            <Typography>{t("perks.text2")}</Typography>

            <Box
              sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              {merchants.map((item) => (
                <Chip key={item} label={item} sx={{ margin: "20px 5px" }} />
              ))}
            </Box>
          </div>

          <ButtonGroup style={{ marginBottom: 0, marginTop: "20px" }}>
            <Button
              onClick={() => navigateTo(routes.perksSniptech)}
              variant="outlined"
            >
              {t("perks.benefits")}
            </Button>
            <Button
              onClick={() => navigateTo(routes.perksSniptechDashboard)}
              variant="outlined"
            >
              {t("perks.wallet")}
            </Button>
          </ButtonGroup>
        </div>

        {/* children (iframe) */}
        <div>{children}</div>
      </Container>
    </>
  );
};

export default Base;
