import { Typography, Grid } from "@mui/material";

import CottageIcon from "@mui/icons-material/Cottage";

function PageHeader() {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <CottageIcon />
      </Grid>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Obten cashback desde tus compras
        </Typography>
        <Typography variant="subtitle2">
          {/* Check all your properties and contracts in one place. */}
          Al comprar en cualquiera de nuestros socios, obtendras cashback a tu
          favor, es decir te devolveran dinero por cada compra que realices.
        </Typography>
        <Typography variant="subtitle2">
          {/* Simply click on a link, go to the merchants online store and obtain
          cashback rewards automatically at the end of the months */}
          Simplemente haz click en "Comprar ahora" y realiza la compra por la
          plataforma a la que serás redirigido.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
