import {Bar, Line} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export type ByMonthIncomesExpenses = {
    expenses: number,
    earnings: number,
    yearMonth: string;
    profit: number;
}[];

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const getBarChartData = (data: ByMonthIncomesExpenses, itemsToPlot: ('earnings' | 'expenses' | 'profit')[]) => {
    const labels = data.map((dataMonth) => dataMonth.yearMonth)

    return {
        labels: labels,
        datasets: itemsToPlot.map((item, idx) => ({
            label: item,
            data: data.map((dataMonth) => dataMonth[item]),
            backgroundColor: `rgba(123, 111, 201, ${(1 - (idx / 1.5))})`,
        }))
    };
};

const options = {
    responsive: true,
    scales: {
        x: {
            // stacked: true,
        },
        y: {
            // stacked: true,
            ticks: {
                // Include a dollar sign in the ticks
                callback: function (value: any) {
                    return "€ " + value;
                },
            },
        },
    },
    plugins: {
        legend: {
            position: "top" as const,
        },
    },
};

const Charts = ({
                    byMonthIncomesExpensesData
                }: {
    byMonthIncomesExpensesData: ByMonthIncomesExpenses
}) => {

    const {t} = useTranslation();

    const expensesEarnings = getBarChartData(byMonthIncomesExpensesData, ['earnings', 'expenses']);

    const profits = getBarChartData(byMonthIncomesExpensesData, ['profit']);

    return (
        <Box sx={{"& >*": {marginY: 7}}}>

            <Box>

                <Typography variant="body1" fontWeight="bold" marginBottom={2}>
                    {t('transactions.incomes')} vs {t('transactions.expenses')}
                </Typography>

                {/* expenses vs incomes */}
                <Bar
                    options={options}
                    data={expensesEarnings}
                    style={{maxHeight: "250px"}}
                />

            </Box>

            {/*  profits */}
            <Box>
                <Typography variant="body1" fontWeight="bold" marginBottom={2}>
                    {t('analytics.profit')}
                </Typography>

                {/* expenses vs incomes */}
                <Line
                    options={options}
                    data={profits}
                    style={{maxHeight: "250px"}}
                />

            </Box>
        </Box>
    );
};

export default Charts;
