import {useAuth} from "../../../hooks/auth";
import {useGetLandlordProfile} from "../../../services/landlord/landlord";
import {useGetUserSearchedProperties} from "../../../services/propertiesSearched";
import BaseSearchedProperties from "./components/Base";

const FavoriteReports = () => {

    const {user} = useAuth();

    const {landlord} = useGetLandlordProfile({userAuthId: user?.id})

    const {properties, isLoading} = useGetUserSearchedProperties({
            landlordId: landlord?.id
        })
    ;

    const propertiesToDisplay = properties?.filter((property) => property.isFavorite);

    return <BaseSearchedProperties properties={propertiesToDisplay} isLoading={isLoading} title={'Your favorites'}/>
}

export default FavoriteReports;