import { createContext, useState } from "react";

import { NotificationType, NotificationsProviderType } from "./types";
import Notification from "./Notification";

export const NotificationContext = createContext<NotificationsProviderType>({
  showNotification: () => {},
});

export const NotificationProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [notification, setNotification] = useState<
    NotificationType | undefined
  >(undefined);

  const handleClose = () => {
    setNotification(undefined);
  };

  const showNotification = (notification: NotificationType) => {
    setNotification(notification);
  };

  const value: NotificationsProviderType = {
    showNotification: showNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}

      {!!notification && (
        <Notification handleClose={handleClose} notification={notification} />
      )}
    </NotificationContext.Provider>
  );
};
