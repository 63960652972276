import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
} from 'chart.js';

import {Radar} from "react-chartjs-2";
import {useGetUserSearchedProperties} from "services/propertiesSearched";
import {useAuth} from "hooks/auth";
import {useGetLandlordProfile} from "services/landlord/landlord";
import {
    Box,
    Chip,
    Container, Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {routes} from "../../../../config/routes";
import BaseAnalytics from "../BaseAnalytics";
import {useLocation} from "react-router-dom";

// Register the components with Chart.js
ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const Comparison = () => {

    const {user} = useAuth();
    const {landlord} = useGetLandlordProfile({userAuthId: user?.id});
    const {properties} = useGetUserSearchedProperties({landlordId: landlord?.id});

    const location = useLocation();
    // get property if it comes from previous page
    const property = location.state?.property;

    const [selectedProperty1, setSelectedProperty1] = useState<number | undefined>(undefined);
    const [selectedProperty2, setSelectedProperty2] = useState<number | undefined>(undefined);

    useEffect(() => {
        //    select property if it comes from previous page
        if (property) setSelectedProperty1(property.id);
    }, []);

    // Handle the selection change for each property
    const handlePropertyChange1 = (event) => {
        const selected = event.target.value;
        // If selecting property 1, clear property 2 if it's the same as the new selection
        if (selected === selectedProperty2) {
            setSelectedProperty2(undefined);
        }
        setSelectedProperty1(selected);
    };

    const handlePropertyChange2 = (event) => {
        const selected = event.target.value;
        // If selecting property 2, clear property 1 if it's the same as the new selection
        if (selected === selectedProperty1) {
            setSelectedProperty1(undefined);
        }
        setSelectedProperty2(selected);
    };

    const propertiesToDisplay = properties?.filter(property =>
        [selectedProperty1, selectedProperty2].includes(property.id)
    );

    const colors = [
        'rgba(255, 99, 132, 0.1)', // Red
        'rgba(54, 162, 235, 0.1)', // Blue
        'rgba(255, 206, 86, 0.1)', // Yellow
        'rgba(75, 192, 192, 0.1)', // Green
        'rgba(153, 102, 255, 0.1)', // Purple
        'rgba(255, 159, 64, 0.1)', // Orange
        'rgba(199, 199, 199, 0.1)' // Gray
    ];

    const borderColors = [
        'rgba(255, 99, 132, 1)', // Red
        'rgba(54, 162, 235, 1)', // Blue
        'rgba(255, 206, 86, 1)', // Yellow
        'rgba(75, 192, 192, 1)', // Green
        'rgba(153, 102, 255, 1)', // Purple
        'rgba(255, 159, 64, 1)', // Orange
        'rgba(199, 199, 199, 1)' // Gray
    ];

    // Prepare data for the radar chart
    const data = propertiesToDisplay?.length > 0 ? {
        labels: propertiesToDisplay[0]?.report.items.map(item => item.item),
        datasets: propertiesToDisplay.map((property, index) => ({
            label: property.propertyInfo.title,
            data: property.report.items.map(item => item.rating),
            backgroundColor: colors[index % colors.length],
            borderColor: borderColors[index % borderColors.length],
            pointBackgroundColor: '#646cec',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#646cec'
        }))
    } : undefined;

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                beginAtZero: true,
                max: 10,
                ticks: {
                    stepSize: 1
                }
            }
        },
        plugins: {
            tooltip: {
                enabled: true,
            }
        }
    };

    // Get the report items for comparison (assuming all properties have the same report structure)
    const reportItems = propertiesToDisplay?.[0]?.report?.items.map(item => item.item) || [];

    const handleCheckReport = (id: number) => {
        const url = `/${routes.reports.base}/${routes.reports.property}/${id}`;
        window.open(url, '_blank');
    };

    const renderCheckReport = (id: number) => {
        return <Typography
            onClick={() => handleCheckReport(id)}
            style={{fontSize: 12, marginTop: 5, textDecoration: 'underline', cursor: 'pointer'}}
        >
            Check report
        </Typography>
    }

    return (
        <BaseAnalytics>

            <Typography variant='h3' style={{marginBottom: 20}}>
                Property Comparing
            </Typography>

            {properties && properties.length > 0 ?
                <Grid container sx={{marginBottom: 4}}>
                    <Grid xs={12}>
                        <Typography style={{
                            marginBottom: 20
                        }}>
                            Select 2 properties to compare:
                        </Typography>
                    </Grid>
                    {/*<Box style={{*/}
                    {/*    marginBottom: 50,*/}
                    {/*    display: 'flex',*/}
                    {/*    justifyContent: 'space-around',*/}
                    {/*    alignItems: 'center'*/}
                    {/*}}>*/}
                    {/*    <Grid container>*/}

                    {/* first selector */}
                    <Grid item xs={12} sm={5} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <InputLabel id="property1-select-label" style={{marginBottom: 10}}>
                            Property 1
                        </InputLabel>
                        <Select
                            labelId="property1-select-label"
                            label='asd'
                            value={selectedProperty1}
                            onChange={handlePropertyChange1}
                            renderValue={(selected) => (
                                <Chip
                                    label={properties.find(prop => prop.id === selected)?.propertyInfo.title || selected}/>
                            )}

                        >
                            {properties?.map((property) => (
                                <MenuItem
                                    key={property.id}
                                    value={property.id}
                                    disabled={property.id === selectedProperty2}
                                >
                                    {property.propertyInfo.title}
                                </MenuItem>
                            ))}
                        </Select>
                        {selectedProperty1 !== undefined && renderCheckReport(selectedProperty1)}
                    </Grid>

                    {/* vs */}
                    <Grid item xs={12} sm={2} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginTop: 2,
                        marginBottom: 1
                    }}>
                        <Typography variant='h5'>
                            v/s
                        </Typography>
                    </Grid>

                    {/* right selector */}
                    <Grid item xs={12} sm={5} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <InputLabel id="property2-select-label" style={{marginBottom: 10}}>
                            Property 2
                        </InputLabel>
                        <Select
                            labelId="property2-select-label"
                            value={selectedProperty2}
                            onChange={handlePropertyChange2}
                            renderValue={(selected) => (
                                <Chip
                                    label={properties.find(prop => prop.id === selected)?.propertyInfo.title || selected}/>
                            )}
                        >
                            {properties?.map((property) => (
                                <MenuItem
                                    key={property.id}
                                    value={property.id}
                                    disabled={property.id === selectedProperty1}
                                >
                                    {property.propertyInfo.title}
                                </MenuItem>
                            ))}
                        </Select>
                        {selectedProperty2 !== undefined && renderCheckReport(selectedProperty2)}
                    </Grid>
                </Grid>
                :
                <Typography>You need to create at least 1 report in order to use this tool.</Typography>}


            {/* Radar Chart */
            }
            <Box style={{minHeight: '300px'}}>
                {data && propertiesToDisplay && propertiesToDisplay.length > 0 && (
                    <Radar
                        data={data}
                        options={options}
                        style={{maxHeight: "1000px"}}
                    />
                )}
            </Box>

            {/* Table with values */
            }
            {
                propertiesToDisplay && propertiesToDisplay.length > 0 &&
                <TableContainer component={Paper} sx={{marginTop: 8}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {propertiesToDisplay.map((property) => (
                                    <TableCell
                                        key={property.id}>{property.propertyInfo.title || `Property ${property.id}`}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportItems.map((itemName, index) => {
                                // Find the highest rating for each item
                                const maxRating = Math.max(
                                    ...propertiesToDisplay.map(property =>
                                        property.report.items.find(i => i.item === itemName)?.rating || 0
                                    )
                                );

                                return (
                                    <TableRow key={index}>
                                        <TableCell>{itemName}</TableCell>
                                        {propertiesToDisplay.map((property) => {
                                            const item = property.report.items.find(i => i.item === itemName);
                                            const isMax = item?.rating === maxRating;

                                            return (
                                                <TableCell
                                                    key={property.id}
                                                    style={{
                                                        fontWeight: isMax ? 'bolder' : 'normal',
                                                        color: isMax ? 'green' : 'gray',
                                                        // fontSize: isMax ? 16 : 12,
                                                        // backgroundColor: isMax ? 'green' : 'gray',
                                                    }}
                                                >
                                                    {item?.rating || 'N/A'}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }

        </BaseAnalytics>
    )
        ;
}

export default Comparison;