import Loader from "../../../components/Loader";
import {Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../../../hooks/auth";
import {supabase} from "../../../config/supabaseClient";
import {routes} from "../../../config/routes";

const PaymentConfirmation = () => {

    // receive stripe link with param CHECKOUT_SESSION_ID
    const [paymentSession, setPaymentSession] = useState('');

    // get session param from query
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const session = queryParams.get('paymentSession');
        setPaymentSession(session);
    }, []);

    const {user} = useAuth();

    const navigate = useNavigate();

    const redirect = () => {
        navigate(`${routes.index}`);
    }

    // update user info
    useEffect(() => {

            // update user payment info
            const updateData = async () => {
                // if param is in url
                if (paymentSession && user) {
                    // update user status on DB to paid?
                    // 1. change number of reports to create
                    const {data, error} = await supabase
                        .from('Landlord')
                        .update({maxReports: 1000})
                        .eq('userAuthId', user.id)
                        .select()
                    // 2. create a status?: active, inactive, etc

                    // 3. create a plan status?

                    // after updating, redirect to home page
                    if (!error) {
                        setTimeout(redirect, 5000)
                    }
                }
            }

            updateData();

        }, [paymentSession, user]
    );


    return <Container>
        <Typography variant='h3' gutterBottom>
            Payment Confirmation
        </Typography>
        <Typography gutterBottom>
            Your payment was successful.
        </Typography>
        <Typography variant={'body1'}>
            We are uploading your account payment information...
        </Typography>
        <Loader/>
    </Container>
}

export default PaymentConfirmation;