import {useEffect, useState} from "react";
import {Box, Button, Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

import {routes} from "config/routes";
import {useGetLandlordProfile} from "services/landlord/landlord";
import {useAuth} from "hooks/auth";
import {useTranslation} from "react-i18next";
import Loader from "../../../components/Loader";


const ConnectBankForLoans = () => {

    const [isLoading, setIsLoading] = useState(true);

    const {t} = useTranslation();

    const navigate = useNavigate();

    const urlCongratulations = `${window.location.origin}/${routes.loans.base}/${routes.loans.congratulations}`;

    const {user} = useAuth();

    const {landlord} = useGetLandlordProfile({
        userAuthId: user.id
    })

    const goToCongratulations = () => navigate(`/${routes.loans.base}/${routes.loans.congratulations}`);

    useEffect(() => {
        if (landlord) {
            // redirect to congratulations if bank is already connected
            if (landlord?.requisitionId) {
                goToCongratulations()
            } else {
                setIsLoading(false)
            }
        }
    }, [landlord]);

    return <>
        <Container maxWidth="lg">

            <Typography variant="h3" gutterBottom> {t('connectBank.title')} </Typography>

            {!isLoading ?
                <>
                    <Box sx={{marginY: 3}}>
                        <Typography gutterBottom>
                            {t('financing.connectBankText1')}
                        </Typography>
                        <Typography>  {t('financing.connectBankText2')}
                        </Typography>
                    </Box>

                    <Button variant="contained" sx={{width: '250px'}} onClick={() => navigate(routes.connectBank, {
                        state: {
                            redirect: urlCongratulations
                        }
                    })}>
                        {t('connectBank.connectMyAccount')}
                    </Button>
                    <Button variant="text" sx={{width: '250px'}}
                            onClick={goToCongratulations}
                    >
                        {t('general.skip')}
                    </Button>
                </> : <Loader/>}
        </Container>
    </>
}

export default ConnectBankForLoans;