import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  MenuItem,
  Select,
  Slider,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { getProperties } from "../../../services/properties/properties";
import { supabase } from "../../../config/supabaseClient";
import { steps } from "./constants";
import { countries } from "../../properties/addProperty/constants";
import { routes } from "../../../config/routes";
import { Property } from "types/entities/property.types";

const SetupTaxes = () => {
  const [step, setStep] = useState(0);

  const [taxesRate, setTaxesRate] = useState(13);

  const [properties, setProperties] = useState<Property[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function func() {
      // get session
      const resp = await supabase.auth.getSession();

      if (!!resp.data.session) {
        const userId = resp.data.session.user.id;
        // get properties
        const propertiesTmp = await getProperties(userId);

        setProperties(propertiesTmp);
      }
    }

    func();
  }, []);

  const customCheckBox = (label: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Checkbox size="small" />
        <Typography variant="body2">{label}</Typography>
      </Box>
    );
  };

  const renderStep = () => {
    switch (step) {
      // first step
      case steps.first:
        return (
          <>
            <Typography variant="body2" gutterBottom>
              Configuremos los impuestos
            </Typography>

            <Typography variant="body2" gutterBottom>
              Escoge una propiedad:
            </Typography>

            {/* checkbox properties */}
            {properties.map((property) => customCheckBox(property.name))}
            {/* checkbox select all properties */}
            {customCheckBox("Elegir todas")}
          </>
        );

      // second step
      case steps.second:
        return (
          <Box
            sx={{
              "&>*": {
                marginBottom: 2,
              },
            }}
          >
            {/* Country selector */}
            <Box>
              <Typography variant="body2" gutterBottom>
                {/* Country where your property is located: */}
                País en donde tu propiedad está ubicada
              </Typography>
              {/* seelct country */}
              <Select label="Country" sx={{ width: "200px" }}>
                {countries.map((city: any) => (
                  <MenuItem value={city}>{city}</MenuItem>
                ))}
              </Select>
            </Box>

            {/* Tax resident question */}
            <Box>
              <Typography variant="body2">
                {/* Are you a tax resident in this country? */}
                Eres un residente fiscal en este país?
              </Typography>
              {customCheckBox("Si")}
              {customCheckBox("No")}
            </Box>
          </Box>
        );

      case steps.third:
        return (
          <>
            <Typography variant="body2">
              {/* What's your tax rate? */}
              Cuál es tu tasa de impuestos?
            </Typography>
            <Box
              sx={{
                width: "50vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Slider
                value={taxesRate}
                onChange={(e) =>
                  setTaxesRate(
                    // @ts-ignore
                    e.target.value
                  )
                }
              />
              <Typography>{taxesRate} %</Typography>
            </Box>
          </>
        );
    }
  };

  const handleFinish = () => {
    alert(
      "Has configurado correctamente la tasa de impuestos asociada a tus propiedades"
    );

    navigate(routes.analytics);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: 2 }}>
        Mis impuestos
      </Typography>

      {/* render step component*/}
      <Box>{renderStep()}</Box>

      {/* continue button */}
      <Box>
        {/* back button */}
        {step !== steps.first && (
          <Button onClick={() => setStep((prev) => prev - 1)}>Atrás</Button>
        )}

        {/* continue or fnish button */}
        {step !== steps.third ? (
          // first setup steps
          <Button
            variant="contained"
            onClick={() => setStep((prev) => prev + 1)}
          >
            {/* Continue */}
            Siguiente
          </Button>
        ) : (
          // finish step
          <Button onClick={handleFinish} variant="contained">
            {/* Finish */}
            Finalizar
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default SetupTaxes;
