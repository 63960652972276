import { useAuth } from "hooks/auth";

import Base from "./Base";

import { getWidgetUrl } from "./utils";

const PerksSniptech = () => {
  const { user } = useAuth();

  const renderWidget = () => {
    return (
      <iframe
        src={getWidgetUrl({ userId: user.id, view: "stores" })}
        title="stores"
        style={{
          border: "none",
          backgroundColor: "transparent",
          width: "100%",
          height: "850px",
        }}
      />
    );
  };

  return (
    <Base>
      <div>{!!user && renderWidget()}</div>
    </Base>
  );
};

export default PerksSniptech;
