import {useEffect} from "react";
import {Box, Button, Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../config/routes";
import {useTranslation} from "react-i18next";

const NewLoanForm = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {

        const script = document.createElement('script');
        script.src = '//embed.typeform.com/next/embed.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            <Container maxWidth="lg">

                <Typography variant='h4' gutterBottom>
                    {t('financing.formTitle')}
                </Typography>
                <Typography sx={{marginBottom: 2}}>
                    {t('financing.formText')}
                </Typography>

                {/* typeform form */}
                <div data-tf-live="01HYKCA1ZVM1YC3SEBH30Q2VF6"></div>

                {/*button next step*/}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                    }}>
                    <Box sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '200px',
                        textAlign: 'center',
                    }}>
                        <Typography variant='body2' sx={{fontSize: 13, marginBottom: 1}}>
                            {t('financing.formMessage')}
                        </Typography>
                        <Button
                            variant='contained'
                            fullWidth
                            sx={{
                                marginBottom: 1,
                                backgroundColor: 'black',
                                color: 'white'
                            }}
                            onClick={() => {

                                const message = t('financing.confirmFillOutForm');

                                if (window.confirm(message)) {
                                    navigate(`/${routes.loans.base}/${routes.loans.connectBank}`)
                                }
                            }}
                        >
                            {t('general.next')}
                        </Button>
                    </Box>
                </Box>

            </Container>
        </>
    );
}

export default NewLoanForm;