import PageTitleWrapper from "components/PageTitleWrapper";
import {Container, Grid} from "@mui/material";
import {Helmet} from "react-helmet";

import PageHeader from "./PageHeader";
import TransactionsTable from "pages/index/components/transactions/Transactions";
import {useAuth} from "../../hooks/auth";
import {useGetLandlordProfile} from "../../services/landlord/landlord";
import {useGetUserTransactions} from "../../services/bankInfo";
import Loader from "../../components/Loader";
import GoToBankComponent from "../components/goToBank";
import {useTranslation} from "react-i18next";

const Transactions = ({hiddeHeader = false}: { hiddeHeader?: boolean }) => {

    const {t} = useTranslation();

    const {user} = useAuth();

    const {landlord, isLoading: isLoadingLandlord} = useGetLandlordProfile({
        userAuthId: user?.id,
    });

    const {transactions, isLoading} = useGetUserTransactions({
        userId: user?.id,
        dateTo: new Date().toString(),
        requisitionId: landlord?.requisitionId,
        lastTransactionDate: landlord?.lastTransactionDate,
        country: landlord?.bankAccountCountry
    })

    const renderTransactions = () => (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <TransactionsTable
                    transactions={transactions}
                    handleEditTransaction={() => {
                    }}
                />
            </Grid>
        </Grid>
    );

    const mainRender = () => {
        if (landlord?.requisitionId !== null) {
            return renderTransactions()
        } else {
            return <GoToBankComponent/>;
        }
    }

    return (
        <>
            <Helmet>
                <title>{t('general.myTransactions')}</title>
            </Helmet>

            {!hiddeHeader && (
                <PageTitleWrapper>
                    <PageHeader/>
                </PageTitleWrapper>
            )}

            <Container maxWidth="lg">
                {(!isLoading && !isLoadingLandlord) ? mainRender() :
                    <Loader message={t('transactions.loading')} bigFont/>}
            </Container>
        </>
    );
};

export default Transactions;
