// TODO: failling with this case:
// The estimated average monthly rental price for this property in Eixample is approximately 4,500 €, resulting in a potential annual yield of around 3.6%.
export function extractEurValue(input) {
    // Match cases where the euro symbol is before or after the amount, allowing for optional spaces and commas
    let euroMatch = input.match(/€\s?([\d,.]+)/); // Format: €6,000 or € 6,000
    let euroAmount = euroMatch ? parseFloat(euroMatch[1].replace(',', '')) : null;

    if (euroAmount === null) {
        euroMatch = input.match(/([\d,.]+)\s?€/); // Format: 4,000 € or 4.500 €
        euroAmount = euroMatch ? parseFloat(euroMatch[1].replace(',', '')) : null;
    }

    return euroAmount;
}

export function extractYield(input) {
    const yieldMatch = input.match(/([\d.]+)%/);

    const yieldPercent = yieldMatch ? parseFloat(yieldMatch[1]) : null;

    return yieldPercent;
}