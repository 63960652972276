import {Typography, Grid, Button} from "@mui/material";

import {useNavigate} from "react-router-dom";
import {routes} from "config/routes";
import {useTranslation} from "react-i18next";

function PageHeader() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
                <Typography variant="h3" component="h3" gutterBottom>
                    {t('analytics.title')} 📊
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    {t('analytics.subtitle1')}
                </Typography>
                <Typography variant="subtitle2">
                    {t('analytics.subtitle2')}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    sx={{mt: {xs: 2, md: 0}}}
                    variant="contained"
                    // startIcon={<AddTwoToneIcon fontSize="small" />}
                    onClick={() => navigate(routes.transactions)}
                >
                    {t('analytics.goToTransactions')}
                </Button>
            </Grid>
        </Grid>
    );
}

export default PageHeader;
