import { Typography, Grid } from "@mui/material";
import {useTranslation} from "react-i18next";

function PageHeader() {
  const {t} = useTranslation();

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {t('general.myTransactions')}
        </Typography>
        <Typography variant="subtitle2">
          {t('transactions.subtitle')}
        </Typography>
      </Grid>
      {/*<Grid item>*/}
      {/*  <Button*/}
      {/*    sx={{ mt: { xs: 2, md: 0 } }}*/}
      {/*    variant="contained"*/}
      {/*    startIcon={<AddTwoToneIcon fontSize="small" />}*/}
      {/*  >*/}
      {/*    Crear transacción*/}
      {/*  </Button>*/}
      {/*</Grid>*/}
    </Grid>
  );
}

export default PageHeader;
