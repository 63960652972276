import {supabase} from "../../../config/supabaseClient";
import {useEffect} from "react";
import {actionsAfterSuccessRegistration} from "../../../services/auth/auth";
import {routes} from "../../../config/routes";
import {useNavigate} from "react-router-dom";

const GoogleCallBack = () => {

    const navigate = useNavigate();

    useEffect(() => {

        const fn = async () => {
            // Step 2: Retrieve the authenticated user session after redirect
            const {data, error} = await supabase.auth.getSession();

            if (error) {
                // console.error('Error retrieving session:', error.message);
            } else if (data.session) {
                const userId = data.session.user.id;

                await actionsAfterSuccessRegistration(userId);

                // redirect to home
                navigate(`${routes.index}`);
            }
        }

        fn();
    }, []);


    return <>Loading...</>
}

export default GoogleCallBack;