import { QueryFunction, QueryKey } from "react-query";

import { supabase } from "config/supabaseClient";
import {
  UpdateBankLandlordInfoPayload,
  UpdateLandlordProfilePayload,
} from "./types";
import { LandLord } from "types/entities/users.types";

/**
 * Request to create/update landlord profile with specific payload.
 */
export const updateLandlordProfileRequest = async (
  updateLandlordProfilePayload: UpdateLandlordProfilePayload
): Promise<any> => {
  const payload = {
    ...updateLandlordProfilePayload,
  };
  const resp = await supabase
    .from("Landlord")
    .update(payload)
    .eq("userAuthId", updateLandlordProfilePayload.userAuthId)
    .select();
  return resp;
};

/**
 * Request to update bank landlord info.
 */
export const updateLandlordBankInfoRequest = async (
  updateBankLandlordInfoPayload: UpdateBankLandlordInfoPayload
): Promise<any> => {
  const payload = {
    ...updateBankLandlordInfoPayload,
  };
  const resp = await supabase
    .from("Landlord")
    .update(payload)
    .eq("userAuthId", updateBankLandlordInfoPayload.userAuthId)
    .select();
  return resp;
};

/**
 * Request to get landlord profile.
 */
export const getLandlordProfile: QueryFunction<LandLord, QueryKey> = async ({
  queryKey,
}: any): Promise<LandLord> => {
  const [_, { userAuthId }] = queryKey as [unknown, any];

  const { data } = await supabase
    .from("Landlord")
    .select("*")
    .eq("userAuthId", userAuthId);

  const resp = !!data && data?.length > 0 ? data[0] : data;

  return resp as LandLord;
};
