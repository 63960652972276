import axios from "axios";

/**
 * Axios instance to manage requests to api
 */
const api = axios.create({
    baseURL: process.env.REACT_APP_BANK_API,
    headers: {
        "Content-Type": "application/json",
    },
});

export default api;
