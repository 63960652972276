// function that parse value to number. If it's not number, return the same value
export const parseNumberToString = (value: number) => {
    // Check if the value is numeric
    if (!isNaN(value)) {
        const formatter = new Intl.NumberFormat("de-DE", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        // Apply formatting if the value is numeric
        let formattedValue = formatter.format(value);
        // Replace the dot with a space
        formattedValue = formattedValue.replace(/\./g, ' ');
        return formattedValue;
    }
    return value;
};