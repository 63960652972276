export const countriesAvailables: { id: string, label: string }[] = [
    {
        id: 'ES',
        label: 'Spain'
    },
    {
        id: 'IT',
        label: 'Italy'
    },
    {
        id: 'DE',
        label: 'Germany'
    }
]