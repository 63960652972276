export enum TransactionType {
    earning = "earning",
    expense = "expense",
}

interface CreditorAccount {
    iban: string;
}

interface TransactionAmount {
    amount: string;
    currency: string;
}

type RawTransaction = {
    valueDate: string;
    bookingDate: string;
    creditorName: string;
    transactionId: string;
    valueDateTime: string;
    bookingDateTime: string;
    creditorAccount: CreditorAccount;
    transactionAmount: TransactionAmount;
    internalTransactionId: string;
    proprietaryBankTransactionCode: string;
    remittanceInformationUnstructuredArray: string[];
};

export type Transaction = {
    id: number;
    title: string;
    // TODO: set as number
    amount: string | number;
    date: string;
    type: TransactionType;
    category: string;
    rawTransaction: RawTransaction;
};