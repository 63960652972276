import { Navigate, Outlet } from "react-router-dom";

import { useAuth } from "hooks/auth";
import { routes } from "config/routes";

/**
 * Basic auth requiring component. If user is not logged, redirect to login.
 */
const AuthRequired = () => {
  const { user } = useAuth();

  // check user is logged
  return !!user ? (
    // render children routes
    <Outlet />
  ) : (
    // redirect to not authorized
    <Navigate to={routes.login} />
  );
};

export default AuthRequired;
