import PageTitleWrapper from "../../components/PageTitleWrapper";
import {
    Box,
    Button, Card, CardContent, CardMedia,
    Container, Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";

import PageHeader from "./PageHeader";
import {useAuth} from "../../hooks/auth";
import {useGetLandlordProfile} from "../../services/landlord/landlord";
import {useNavigate} from "react-router-dom";
import {supabase} from "../../config/supabaseClient";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import GoToBankComponent from "../components/goToBank";

import step1 from "static/step1.png";
import step2 from "static/step2.png";
import step3 from "static/step3.png";
import {routes} from "../../config/routes";
import LoanEstimationBasedOnIncomes from "./components/LoanEstimationBasedOnIncomes";

type Loan = {
    id: string;
    title: string;
    status: 'pending' | 'accepted' | 'rejected',
    userId: string;
};

const Loans = () => {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const {user} = useAuth();

    const {landlord} = useGetLandlordProfile({
        userAuthId: user?.id
    })

    const [loans, setLoans] = useState<Loan[]>(undefined);

    useEffect(() => {
        (async () => {
            if (user) {

                let {data: loans} = await supabase
                    .from('Loans')
                    .select('*')
                    .eq('userId', user.id)

                setLoans(loans as Loan[]);
            }
        })()
    }, [user]);

    const isBankConnected = landlord?.requisitionId;

    const goToCreateLoan = async () => {
        // const message = t('financing.newLoanConfirm');

        // if (window.confirm(message)) {
        if (!!user && loans !== undefined) {

            // create payload
            const payload: Omit<Loan, 'id'> = {
                status: 'pending',
                userId: user.id,
                title: `Loan ${loans.length + 1}`
            }

            // create loan in DB and redirect to loan form
            const {
                error
            }
                = await supabase
                .from('Loans')
                .insert(payload)
                .select()

            // redirect
            if (!error) {
                navigate(routes.loans.newLoanForm)
            }
            // // if error, try again
            // else {
            //     alert("Tuvimos un error, intentalo de nuevo")
            // }
            // }
        }
    };

    const firstTimeRender = () => {

        return <Box>

            <Typography variant='h3' gutterBottom> 💰 {t('financing.title')}</Typography>

            <Box sx={{marginY: 3}}>
                <Typography gutterBottom>
                    {t('financing.text1')}
                </Typography>
                <Typography>
                    {t('financing.text2')}
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
            }}>
                <Box sx={{
                    width: 'fit-content',
                    marginBottom: 2
                }}>
                    <Grid container spacing={3} sx={{display: 'flex', justifyContent: 'center'}}>
                        {[{
                            icon: step1,
                            description: t('financing.step1')
                        },
                            {
                                icon: step2,
                                description: t('financing.step2')
                            }, {
                                icon: step3,
                                description: t('financing.step3')
                            }
                        ].map((item, idx) => {

                            return (
                                <Grid item sm={12} md={4}>
                                    <Card sx={{maxWidth: 330, marginRight: 3, marginBottom: 3, height: '300px'}}>
                                        <CardMedia
                                            component="img"
                                            image={item.icon}
                                            alt="Paella dish"
                                            sx={{
                                                height: '150px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <CardContent>
                                            <Box style={{display: 'flex', justifyContent: 'center', marginBottom: 20}}>
                                                <Typography variant="h5">
                                                    {t('financing.step')} {idx + 1}
                                                </Typography>
                                            </Box>
                                            <Typography>
                                                {item.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>)
                        })}

                    </Grid>
                </Box>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={goToCreateLoan}
                        variant="contained"
                        sx={{width: '350px', height: '50px'}}
                        disabled={!user && loans === undefined}
                >
                    {t('general.apply')}
                </Button>
            </Box>
        </Box>
    }

    const normalRender = () => {
        return (
            <>
                {/*connect bank component*/}
                {
                    !isBankConnected &&
                    <GoToBankComponent/>
                }

                {/* loan calculator*/}
                {/*{*/}
                {/*    isBankConnected && <LoanEstimationBasedOnIncomes/>*/}
                {/*}*/}
                <Typography variant='h3'> {t('financing.myLoans')}</Typography>

                <TableContainer>
                    <Table aria-label="simple table">
                        {/* table header */}
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight: "bold"}}> </TableCell>
                                {/*<TableCell sx={{fontWeight: "bold"}}>Date</TableCell>*/}
                                <TableCell sx={{fontWeight: "bold"}}>Status</TableCell>
                                {/*<TableCell sx={{ fontWeight: "bold" }}></TableCell>*/}
                            </TableRow>
                        </TableHead>

                        {/* table body */}
                        <TableBody>
                            {!!loans && loans.length > 0 ? loans?.map((loan) => (
                                // each row
                                <TableRow key={loan.id}>
                                    <TableCell component="th" scope="row">
                                        <Box>
                                            <Typography sx={{fontWeight: "bold"}} variant="body2">
                                                {loan.title}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    {/*<TableCell component="th" scope="row">*/}
                                    {/*    {loan.title}*/}
                                    {/*</TableCell>*/}

                                    <TableCell component="th" scope="row">
                                        {loan.status}
                                    </TableCell>
                                </TableRow>
                            )) : (
                                <Box sx={{textAlign: 'center', paddingTop: 2}}>
                                    <Typography>
                                        {t('general.noDataYet')}
                                    </Typography>
                                </Box>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    const hasLoans = !!loans ? loans.length > 0 : false;
// const hasLoans = false;

    const render = () => {

        if (hasLoans) {
            return normalRender()
        } else {
            return firstTimeRender()
        }
    }
    return (
        <>
            {/*<Helmet>*/}
            {/*    <title>Nido - Home</title>*/}
            {/*</Helmet>*/}

            {hasLoans && <PageTitleWrapper>
                <PageHeader goToCreateLoan={goToCreateLoan}/>
            </PageTitleWrapper>}

            <Container maxWidth="lg">
                {render()}
            </Container>
        </>
    )
        ;
}

export default Loans;