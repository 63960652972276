import { useMutation, useQuery } from "react-query";

import { MutationOptionalPayload } from "types/requests.types";
import {
  UpdateBankLandlordInfoPayload,
  UpdateLandlordProfilePayload,
} from "./types";
import {
  getLandlordProfile,
  updateLandlordBankInfoRequest,
  updateLandlordProfileRequest,
} from "./landlord.requests";
import { LandLord } from "types/entities/users.types";

const resource = "landlord";

/**
 * Hook to update bank landlord information.
 */
export const useUpdateBankLandlordInformation = ({
  onSuccess,
  onError,
}: MutationOptionalPayload) => {
  return useMutation<LandLord, Error, UpdateBankLandlordInfoPayload>({
    mutationFn: updateLandlordBankInfoRequest,
    onSuccess,
    onError,
  });
};

/**
 * Hook to create/update landlord profile with specific payload.
 */
export const useUpdateLandlordProfile = ({
  onSuccess,
  onError,
}: MutationOptionalPayload) => {
  return useMutation<LandLord, Error, UpdateLandlordProfilePayload>({
    mutationFn: updateLandlordProfileRequest,
    onSuccess,
    onError,
  });
};

/**
 * Hook to get landlord profile.
 */
export const useGetLandlordProfile = ({
  userAuthId,
}: {
  userAuthId: string | undefined;
}) => {
  let { data, refetch, isLoading } = useQuery<LandLord, Error>(
    [resource, { userAuthId }],
    getLandlordProfile,
    {
      enabled: !!userAuthId,
    }
  );

  return { landlord: data, refetch, isLoading };
};
