import {
    Box,
    Button,
    ButtonGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import {useState} from "react";

import {Transaction, TransactionType} from "../../../../types/entities/transactions";
import {parseNumberToString} from "../../../../utils/nmbers";
import {useTranslation} from "react-i18next";

const TransactionsTable = ({
                               transactions,
                               handleEditTransaction,
                           }: {
    transactions: Transaction[] | undefined;
    handleEditTransaction: () => void;
}) => {

    const {t} = useTranslation();

    const menuOptions = {
        allTransactions: {label: t('transactions.all'), value: "allTransactions"},
        earning: {label: t('transactions.incomes'), value: TransactionType.earning},
        expense: {label: t('transactions.expenses'), value: TransactionType.expense},
    };

    const [displayedTransactions, setDisplayedTransactions] =
        useState(transactions);

    const [selectedOption, setSelectedOption] = useState<
        typeof menuOptions.allTransactions
    >(menuOptions.allTransactions);

    const handleOption = (option: keyof typeof menuOptions) => {
        setSelectedOption(menuOptions[option]);

        // all transactions
        if (option === menuOptions.allTransactions.value) {
            setDisplayedTransactions(transactions);
        }

        // earning
        else if (option === menuOptions.earning.value) {
            const newTransactions = transactions?.filter(
                (transaction) => transaction.type === TransactionType.earning
            );
            setDisplayedTransactions(newTransactions);
        }

        // expenses
        else {
            const newTransactions = transactions?.filter(
                (transaction) => transaction.type === TransactionType.expense
            );
            setDisplayedTransactions(newTransactions);
        }
    };

    return (
        <Box sx={{"& >*": {marginBottom: 3}}}>
            {/* section title */}
            {/*<Typography variant="body1" fontWeight="bold" marginBottom={2}>*/}
            {/*    {t('transactions.title')}*/}
            {/*</Typography>*/}

            {/* select tab */}
            <ButtonGroup>
                {Object.keys(menuOptions).map((key) => (
                    <Button
                        key={key}
                        onClick={() => handleOption(key as keyof typeof menuOptions)}
                        sx={{
                            background: selectedOption.value === key ? "rgba(187, 173, 249, 0.2)" : ""
                        }}
                    >
                        {/*<b>{menuOptions[key].label}</b>*/}
                        {menuOptions[key].label}
                    </Button>
                ))}
            </ButtonGroup>

            {/* table with transactions */}

            {/* next 2 boxes are to set the width of the table and be responsive */}
            <Box sx={{overflow: "auto"}}>
                <Box sx={{width: "100%", display: "table", tableLayout: "fixed"}}>
                    <TableContainer>
                        <Table aria-label="simple table">
                            {/* table header */}
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: "bold"}}>Item</TableCell>
                                    <TableCell sx={{fontWeight: "bold"}}>{t('transactions.date')}</TableCell>
                                    <TableCell sx={{fontWeight: "bold"}}>{t('transactions.amount')}</TableCell>
                                    {/*<TableCell sx={{ fontWeight: "bold" }}></TableCell>*/}
                                </TableRow>
                            </TableHead>

                            {/* table body */}
                            <TableBody>
                                {!!displayedTransactions && displayedTransactions.length > 0 ? displayedTransactions?.map((transaction) => (
                                    // each row
                                    <TableRow key={transaction.id}>
                                        <TableCell component="th" scope="row">
                                            <Box>
                                                <Typography sx={{fontWeight: "bold"}} variant="body2">
                                                    {transaction.title}
                                                </Typography>
                                                {/*hidde transaction for now*/}
                                                {/*{parseFloat(transaction.amount.toString()) < 0 &&*/}
                                                {/*    <Typography sx={{color: "#5b5b5b80"}} variant="body2">*/}
                                                {/*        {transaction.category}*/}
                                                {/*    </Typography>}*/}
                                            </Box>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {transaction.date}
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                color={
                                                    transaction.type === TransactionType.earning
                                                        ? "green"
                                                        : "black"
                                                }
                                            >
                                                € {parseNumberToString(parseFloat(transaction.amount.toString()))}
                                            </Typography>
                                        </TableCell>
                                        {/*<TableCell>*/}
                                        {/*  <Button onClick={handleEditTransaction}>Edit</Button>*/}
                                        {/*</TableCell>*/}
                                    </TableRow>
                                )) : <Box sx={{textAlign: 'center', paddingTop: 2}}>
                                    <Typography>
                                        No hay data disponible aún
                                    </Typography>
                                </Box>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>

            {/* load more transacionts */}
            {/*<Box sx={{ display: "flex", justifyContent: "center" }}>*/}
            {/*  <Button variant="outlined">Load more transactions</Button>*/}
            {/*</Box>*/}
        </Box>
    );
};

export default TransactionsTable;
